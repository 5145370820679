import React from 'react';
import { useCreatePath, useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

const UserLinkField = (props) => {
  const { idKey = 'userId', nameKey = 'userName', emptyText = '-', data = null } = props;

  const createPath = useCreatePath();
  const record = useRecordContext(props);

  const userId = get(data || record, idKey);
  const userName = get(data || record, nameKey);

  if (userId === null || userId === undefined || userId === '') {
    return userName || emptyText;
  }

  const userShowPage = createPath({ resource: 'users', type: 'show', id: userId });

  return (
    <Link to={userShowPage} onClick={(e) => e.stopPropagation()}>
      {userName ?? userId}
    </Link>
  );
};

export default UserLinkField;
