import isEmpty from 'lodash/isEmpty';

import { httpClient } from '~/utils/dataProvider';
import {
  STORAGE_KEY_ROLES,
  STORAGE_KEY_ACCESS_TOKEN,
  STORAGE_KEY_REFRESH_TOKEN
} from '~/utils/constants';

import clearStorage from './clearStorage';

export default {
  login: async ({ username, password }) => {
    const { json } = await httpClient('/auth/sign-in', {
      method: 'POST',
      body: {
        email: username,
        password
      }
    });

    const { accessToken, refreshToken, data } = json ?? {};
    const roles = JSON.stringify([data?.role]);

    if (!accessToken || !refreshToken) {
      throw new Error('ra.auth.sign_in_error');
    }

    localStorage.setItem(STORAGE_KEY_ACCESS_TOKEN, accessToken);
    localStorage.setItem(STORAGE_KEY_REFRESH_TOKEN, refreshToken);
    localStorage.setItem(STORAGE_KEY_ROLES, roles);
  },
  checkError: async (error) => {
    const { status } = error;

    if (status === 401) {
      clearStorage();
      return Promise.reject();
    }

    if (status === 403) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY_ACCESS_TOKEN);

      if (accessToken) {
        return Promise.resolve();
      }

      return Promise.reject();
    } catch (e) {
      console.error(e);

      return Promise.reject();
    }
  },
  logout: () => {
    clearStorage();

    return Promise.resolve('/login');
  },
  getIdentity: () => {
    return Promise.resolve();
  },
  getPermissions: () => {
    try {
      const roles = JSON.parse(localStorage.getItem(STORAGE_KEY_ROLES));

      if (isEmpty(roles)) {
        return Promise.resolve([]);
      }

      return Promise.resolve(roles);
    } catch (e) {
      console.error(e);

      return Promise.reject();
    }
  }
};
