import React, { useState } from 'react';
import { TextInput, maxLength } from 'react-admin';

const InputWithMaxCount = ({ maxCount = 10, validate, ...otherProps }) => {
  const [count, setCount] = useState(0);

  const handleChange = ({ target: { value } }) => setCount(value?.length || 0);

  return (
    <TextInput
      {...otherProps}
      onChange={handleChange}
      helperText={`${count} / ${maxCount}`}
      validate={[maxLength(maxCount), ...validate]}
    />
  );
};

export default InputWithMaxCount;
