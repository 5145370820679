import React from 'react';
import { usePermissions } from 'react-admin';
import intersection from 'lodash/intersection';

import { ROLES } from '~/utils/constants';

const withAccessChecking = (Component, allowedRoles = []) => {
  return (props) => {
    const { permissions = [] } = usePermissions();
    const hasntPermissions = permissions.length === 0;

    if (hasntPermissions) {
      return null;
    }

    const allowedRolesWithAdmin = [...allowedRoles, ROLES.ADMIN];
    const intersections = intersection(allowedRolesWithAdmin, permissions);
    const hasntIntersections = intersections.length === 0;

    if (hasntIntersections) {
      return null;
    }

    return <Component {...props} />;
  };
};

export default withAccessChecking;
