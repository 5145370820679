import * as React from 'react';
import { createElement } from 'react';
import {
  MenuItemLink,
  DashboardMenuItem,
  Menu as RAMenu,
  useSidebarState,
  useResourceDefinitions
} from 'react-admin';
import SettingsIcon from '@mui/icons-material/Settings';

// import { useAccessChecking } from '~/utils/helpers';

const Menu = (props) => {
  const { onMenuClick, dense } = props;
  // const hasAccess = useAccessChecking();
  const resourcesDefinitions = useResourceDefinitions();
  const [isOpen] = useSidebarState();
  const resources = Object.keys(resourcesDefinitions).map((name) => resourcesDefinitions[name]);

  const renderMenuItemLink = (resource) => {
    // if (!hasAccess(resource?.accessAllowed)) {
    //   return null;
    // }

    if (!resource.options?.label || !resource.icon) {
      return null;
    }

    return (
      <MenuItemLink
        key={resource.name}
        to={`/${resource.name}`}
        primaryText={resource.options.label}
        leftIcon={createElement(resource.icon)}
        onClick={onMenuClick}
        sidebarIsOpen={isOpen}
        dense={dense}
      />
    );
  };

  return (
    <RAMenu {...props}>
      <DashboardMenuItem />
      {resources.map(renderMenuItemLink)}
      <MenuItemLink
        key="settings"
        to="/settings"
        primaryText="Settings"
        leftIcon={createElement(SettingsIcon)}
        onClick={onMenuClick}
        sidebarIsOpen={isOpen}
        dense={dense}
      />
    </RAMenu>
  );
};

export default Menu;
