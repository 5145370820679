import { mapToSelector } from '~/utils/helpers';

export const LOOKING_FOR_GENDERS_MAP = {
  MALE: 'male',
  FEMALE: 'female',
  BOTH: 'both'
};

export const NOTIFICATION_TYPES_MAP = {
  POST_SCREEN: 'datePage',
  USER_PROFILE: 'userProfile',
  CUSTOM_URL: 'customWebUrl'
};

export const NOTIFICATION_DATA_KEY_MAP = {
  URL: 'url',
  TYPE: 'type',
  DATE_ID: 'dateId',
  USER_ID: 'userId'
};

export const USER_STATUS_KEY_MAP = {
  NOT_COMPLITED: 'notComplited',
  PENDING: 'pending',
  ACTIVE: 'active',
  REJECTED: 'rejected'
};

export const USER_GENDERS_MAP = {
  MALE: 'male',
  FEMALE: 'female',
  AGENDER: 'agender',
  ANDROGYNOUS: 'androgynous',
  BIGENDER: 'bigender',
  GENDER_FLUID: 'genderFluid',
  GENDER_NONCONFORMING: 'genderNonconforming',
  GENDERQUEER: 'genderqueer',
  GENDER_QUESTIONING: 'genderQuestioning',
  INTERSEX: 'intersex',
  NON_BINARY: 'nonBinary',
  PANGENDER: 'pangender',
  TRANS_HUMAN: 'transHuman',
  TRANS_MAN: 'transMan',
  TRANS_WOMAN: 'transWoman',
  TRANSFEMININE: 'transfeminine',
  TRANSMASCULINE: 'transmasculine',
  TWO_SPIRIT: 'twoSpirit',
  OTHER: 'other'
};

export const SIGN_UP_STEPS_MAP = {
  WAITLIST_S1: 'waitlist',
  LAUNCH_WALL_S2: 'launch-wall',
  IS_IN_S2_FLOW: 's1-approved'
};

export const USER_ROLES_MAP = {
  USER: 'user',
  TEST_USER: 'testUser',
  BETA_USER: 'betaUser',
  WAITLIST_USER: 'waitlistUser',
  AMBASSADOR: 'ambassador',
  DECLINED: 'declined'
};

export const PREMIUM_TYPES_MAP = {
  BASIC: 'basic'
};

export const LOOKING_FOR_GENDERS = mapToSelector(LOOKING_FOR_GENDERS_MAP);
export const NOTIFICATION_TYPES = mapToSelector(NOTIFICATION_TYPES_MAP);
export const PREMIUM_TYPES = mapToSelector(PREMIUM_TYPES_MAP);
export const USER_GENDERS = mapToSelector(USER_GENDERS_MAP);
export const USER_ROLES = mapToSelector(USER_ROLES_MAP);
export const SIGN_UP_STEPS = mapToSelector(SIGN_UP_STEPS_MAP);

export const SOCIAL_MEDIA_LINK_KEYS = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINKED_IN: 'linkedin',
  TIKTOK: 'tiktok',
  SNAPCHAT: 'snapchat',
  INSTAGRAM: 'instagram'
};

export const PROFILE_SECTIONS_CONTENT_TYPES = {
  TEXT: 'text',
  AUDIO: 'audio',
  VIDEO: 'video',
  PHOTO: 'photo'
};
