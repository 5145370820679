import { useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-admin';
import jwtDecode from 'jwt-decode';

import { STORAGE_KEY_ACCESS_TOKEN } from '~/utils/constants';

import refreshAccessToken from './refreshAccessToken';

export default () => {
  const { authenticated } = useAuthState();
  const [tokenUpdatedCount, setTokenUpdatedCount] = useState(0);
  const interval = useRef();

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    const accessToken = localStorage.getItem(STORAGE_KEY_ACCESS_TOKEN);

    if (!accessToken) {
      return;
    }

    try {
      const tokenRefreshInterval = (jwtDecode(accessToken).exp * 1000 - Date.now()) / 2;

      if (interval.current) {
        clearTimeout(interval.current);
      }

      interval.current = setTimeout(doRefresh, tokenRefreshInterval);
    } catch (err) {
      console.log(err);
    }

    return () => clearTimeout(interval.current);
  }, [tokenUpdatedCount, authenticated]);

  const doRefresh = async () => {
    const success = await refreshAccessToken();

    if (!success) {
      return;
    }

    setTokenUpdatedCount((prev) => prev + 1);
  };
};
