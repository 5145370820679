import React from 'react';
import { CardContent, Button, CircularProgress, Grid } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useNotify } from 'react-admin';
import { useForm } from 'react-hook-form';

import { useHttp } from '~/utils/dataProvider';
import { CUSTOM_ENDPOINTS, REQUEST_TYPES } from '~/utils/constants';

import CheckboxInput from './CheckboxInput';
import NumberInput from './NumberInput';
import DateTimeInput from './DateTimeInput';

export default ({ defaultValues }) => {
  const notify = useNotify();
  const [{ loading }, setGlobalSettings] = useHttp(
    CUSTOM_ENDPOINTS.updateGlobalSettings(),
    REQUEST_TYPES.PUT,
    {
      onSuccess: () => notify('Global settings updated successfully', { type: 'success' }),
      onError: (e) => notify(e.message, { type: 'error' })
    }
  );

  const { handleSubmit, control } = useForm({
    defaultValues
  });

  const onSubmit = async (values) => {
    await setGlobalSettings({ body: values });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContent>
        <Grid container spacing={8}>
          <Grid item xs={12} md={3}>
            <CheckboxInput
              control={control}
              name="blockMediaInMessages"
              label="Block media in chat messages: "
            />
            <CheckboxInput control={control} name="enableWaitlist" label="Waitlist enabled: " />
            <CheckboxInput control={control} name="unlimitInvites" label="Unlimited invites: " />
            {/* <CheckboxInput
              control={control}
              name="showSearchFilters"
              label="Show search filters: "
            /> */}
          </Grid>
          <Grid item xs={12} md={3}>
            <NumberInput
              control={control}
              name="maxDistance"
              label="Max Distance"
              helperText="Use -1 for unlimited"
            />
            <NumberInput control={control} name="minAge" label="Min Age" />
            <NumberInput
              control={control}
              name="maxAge"
              label="Max Age"
              helperText="Use -1 for unlimited"
            />
            <NumberInput
              control={control}
              name="userInviteLimit"
              label="Full user invites (default value)"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <NumberInput
              control={control}
              name="waitlistInviteLimit"
              label="Waitlist user invites (default value)"
            />
            <NumberInput
              control={control}
              name="maxNewDateRadius"
              label="Max new date radius"
              helperText="Use -1 for unlimited"
            />
            <DateTimeInput
              control={control}
              name="officialLaunchDate"
              label="Official launch date"
            />
            <DateTimeInput
              control={control}
              name="freeBasicPremiumEndDate"
              label="Free basic premium end date"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <NumberInput
              control={control}
              name="waitlistMultiplier"
              label="Number to multiply waitlist"
            />
            <DateTimeInput
              control={control}
              name="freeBasicPremiumStartDate"
              label="Free basic premium start date"
            />

            <NumberInput
              control={control}
              name="maxEmailVerificationNotifications"
              label="Max email verification"
              helperText="Use -1 for unlimited"
            />
            <NumberInput
              control={control}
              name="minAmountOfUserMedia"
              label="Min amount of user media"
              min="1"
              max="6"
              step="1"
            />
            {/* <NumberInput
              control={control}
              name="swipesBeforeShowingPopup"
              label="swipesBeforeShowingPopup"
            />
            <NumberInput
              control={control}
              name="newDateMaxDays"
              label="Max new date days in future"
              helperText="Use -1 for unlimited"
            />
            <NumberInput
              control={control}
              name="maxAmountOfDatesLive"
              label="Max amount of dates"
              helperText="Use -1 for unlimited"
            /> */}
          </Grid>
        </Grid>
      </CardContent>
      <CardContent style={{ backgroundColor: '#f5f5f5', padding: '16px' }}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<SaveIcon />}
          type="submit"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'save'}
        </Button>
      </CardContent>
    </form>
  );
};
