import React from 'react';
import {
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  BooleanField,
  SimpleShowLayout
} from 'react-admin';

import FullScreenImage, { useFullScreenImageStyles } from '~/components/FullScreenImage';
import useStyles from '~/components/styles';

import AcceptedUsersField from './AcceptedUsersField';
import TagsField from './TagsField';

export default ({ children }) => {
  const fullScreenImageClasses = useFullScreenImageStyles();
  const classes = useStyles();

  return (
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="title" label="Title" />
      <TextField source="description" label="Description" />
      <TextField source="city" label="City" />
      <TextField source="address" label="Address" />
      <TextField source="visibility" label="Visibility" />
      <ArrayField source="files">
        <SingleFieldList linkType={false} className={classes.singleFieldList}>
          <FullScreenImage
            src="filePath"
            preview="previewPath"
            title="Event image"
            previewImageClassName={fullScreenImageClasses.verticalPreviewImage}
            fullImageClassName={fullScreenImageClasses.verticalFullImage}
          />
        </SingleFieldList>
      </ArrayField>
      <TagsField label="Tags" />
      <DateField source="date" label="Date time" locales="en-EN" showTime />
      <BooleanField source="invitingClosed" label="Closed" />
      <AcceptedUsersField label="Accepted users" />
      {children}
    </SimpleShowLayout>
  );
};
