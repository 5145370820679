import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const MultipleSelectChip = ({
  value: currentValue,
  label: inputLabel,
  choices,
  valueKey = 'value',
  labelKey = 'label',
  className,
  size = 'medium',
  formControlProps = {},
  ...rest
}) => {
  const theme = useTheme();
  const labelId = `${inputLabel}-multiple-select-chip-label`;

  const getStyles = (itemValue) => ({
    fontWeight:
      currentValue.indexOf(itemValue) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  });

  return (
    <FormControl fullWidth {...formControlProps} className={className} size={size}>
      <InputLabel id={labelId}>{inputLabel}</InputLabel>
      <Select
        {...rest}
        labelId={labelId}
        multiple
        value={currentValue}
        input={<OutlinedInput label={inputLabel} />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {choices.map((item) => (
          <MenuItem key={item[valueKey]} value={item[valueKey]} style={getStyles(item[valueKey])}>
            {item[labelKey]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelectChip;
