import { mapToSelector } from '~/utils/helpers';

export const REPORT_TYPE_MAP = {
  DATING: 'dating',
  USER: 'user'
};

export const REPORT_REASON_MAP = {
  NUDITY: 'nudity',
  FAKE_PROFILE: 'fakeProfile',
  OTHER: 'other',
  INAPPROPRIATE_TEXT: 'inappropriateText'
};

export const REPORT_TYPES = mapToSelector(REPORT_TYPE_MAP);

export const REPORT_REASONS = mapToSelector(REPORT_REASON_MAP);
