import React from 'react';
import {
  SimpleShowLayout,
  ArrayField,
  Datagrid,
  TextField,
  BooleanField,
  FunctionField,
  useRecordContext,
  useNotify,
  useRefresh
} from 'react-admin';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import FullScreenImage from '~/components/FullScreenImage';
import { useHttp } from '~/utils/dataProvider';
import { CUSTOM_ENDPOINTS, REQUEST_TYPES } from '~/utils/constants';

const ContentField = (props) => {
  return (
    <FunctionField
      {...props}
      render={(record) => {
        if (record.isVideo) {
          return (
            <video width="240" height="160" controls>
              <source src={record.filePath} />
              Your browser does not support the video tag.
            </video>
          );
        }

        return <FullScreenImage src="filePath" preview="previewPath" title="gallery image" />;
      }}
    />
  );
};

const StateSelector = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const [{ isLoading }, setFileState] = useHttp(
    CUSTOM_ENDPOINTS.setFileState({ fileId: record.id }),
    REQUEST_TYPES.PUT,
    {
      onSuccess: () => {
        refresh();
        notify('State updated successfully', { type: 'success' });
      },
      onError: (e) => notify(e.message, { type: 'error' })
    }
  );

  return (
    <Select
      defaultValue={record.state}
      onChange={({ target: { value: state } }) => {
        setFileState({ body: { state } });
      }}
      disabled={isLoading}
    >
      <MenuItem value="allowed">Allowed</MenuItem>
      <MenuItem value="blocked">Blocked</MenuItem>
    </Select>
  );
};

const GalleryTabContent = () => {
  return (
    <SimpleShowLayout>
      <ArrayField source="files" label="">
        <Datagrid
          bulkActionButtons={false}
          empty={<Typography variant="h6">No gallery</Typography>}
        >
          <TextField source="id" label="ID" sortable={false} />
          <TextField source="fileName" label="File name" sortable={false} />
          <BooleanField source="fromCamera" title="From camera" sortable={false} />
          <StateSelector label="State" sortable={false} />
          <ContentField label="Content" sortable={false} />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  );
};

export default GalleryTabContent;
