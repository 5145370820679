import React from 'react';
import { FunctionField } from 'react-admin';

export const QUESTION_TYPES_MAP = {
  locationAutocomplete: 'locationAutocomplete',
  selector: 'selector',
  input: 'input',
  tags: 'tags'
};

export const QUESTION_TYPES_ENUM = {
  1: QUESTION_TYPES_MAP.selector,
  2: QUESTION_TYPES_MAP.input,
  3: QUESTION_TYPES_MAP.tags,
  4: QUESTION_TYPES_MAP.locationAutocomplete
};

const QuestionTypeField = ({ source, ...other }) => {
  const render = (record) => {
    const typeKey = record[source];

    if (typeKey === null || !(typeKey in QUESTION_TYPES_ENUM)) {
      return '-';
    }

    return QUESTION_TYPES_ENUM[typeKey].toUpperCase();
  };

  return <FunctionField {...other} render={render} />;
};

export default QuestionTypeField;
