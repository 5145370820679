import React from 'react';
import { TextField, DateField, Datagrid, List, TextInput } from 'react-admin';

import UserLinkField from '~/components/UserLinkField';

export const defaultFilters = [
  <TextInput key="userId" source="userId" label="User ID" resettable />
];

const ScreenshotsList = (props) => {
  return (
    <List
      title=" Who takes screenshots"
      perPage={10}
      exporter={false}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={defaultFilters}
      {...props}
    >
      <Datagrid bulkActionButtons={false}>
        <UserLinkField label="User" />
        <TextField
          source="screenshottedPagePath"
          label="Screenshotted page path"
          emptyText="-"
          sortable={false}
        />
        <DateField
          source="date"
          label="Date"
          locales="en-EN"
          emptyText="-"
          showTime
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default ScreenshotsList;
