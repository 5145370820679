import React from 'react';
import { SimpleForm, TextInput, DateInput, ImageInput, ImageField } from 'react-admin';

import CustomToolbar from '~/components/CustomToolbar';

export const transform = (data) => {
  const formData = new FormData();

  formData.append('title', data.title);
  formData.append('drawingDate', data.drawingDate);

  const file = data?.prizeImage?.rawFile;

  if (file !== null && file !== undefined) {
    formData.append('file', file);
  }

  return formData;
};

const dateParser = (value) => {
  if (!value || value.length <= 0) {
    return '';
  }

  const parsedDate = new Date(value).toISOString();

  return parsedDate;
};

const imageValidator = (value) => {
  if (value === null || value === undefined) {
    return 'Please select at least one picture';
  }
  return undefined;
};

export default ({ children, ...otherProps }) => {
  return (
    <SimpleForm toolbar={<CustomToolbar />} {...otherProps}>
      <div style={{ width: 300 }}>
        <TextInput source="title" label="Title" required fullWidth />
        <DateInput
          source="drawingDate"
          label="Drawing date"
          parse={dateParser}
          defaultValue={new Date()}
          required
          fullWidth
        />
        <ImageInput
          source="prizeImage"
          label="Prize picture"
          accept="image/*"
          required
          multiple={false}
          validate={imageValidator}
        >
          <ImageField source="filePath" />
        </ImageInput>
        {children}
      </div>
    </SimpleForm>
  );
};
