import React from 'react';
import { useRecordContext, useNotify, useRefresh } from 'react-admin';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { useHttp } from '~/utils/dataProvider';
import { CUSTOM_ENDPOINTS, REQUEST_TYPES } from '~/utils/constants';

import { EVENT_PARTICIPANT_VIP_STATUSES } from '../constants';

const EventParticipantVIPStatusSelector = ({ eventId: datingUUID }) => {
  const { vipStatus, id: requesterUUID } = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const [{ isLoading }, setVipStatus] = useHttp(
    CUSTOM_ENDPOINTS.setDatingResponsesVIPStatus(),
    REQUEST_TYPES.PUT,
    {
      onSuccess: () => {
        refresh();
        notify('VIP status updated successfully', { type: 'success' });
      },
      onError: (e) => notify(e.message, { type: 'error' })
    }
  );

  return (
    <Select
      defaultValue={vipStatus}
      onChange={({ target: { value } }) => {
        setVipStatus({ body: { vipStatus: value, datingUUID, requesterUUID } });
      }}
      disabled={isLoading}
      style={{ width: 92 }}
    >
      {EVENT_PARTICIPANT_VIP_STATUSES.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default EventParticipantVIPStatusSelector;
