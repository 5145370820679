import { hot } from 'react-hot-loader/root';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Admin, Resource, CustomRoutes, memoryStore } from 'react-admin';

import Users from '~/resources/Users';
import Events from '~/resources/Events';
import Prizes from '~/resources/Prizes';
import Reports from '~/resources/Reports';
import Screenshots from '~/resources/Screenshots';
import Answers from '~/resources/Answers';
import Ambassadors from '~/resources/Ambassadors';
import SupportMessages from '~/resources/SupportMessages';
import UserVerifications from '~/resources/UserVerifications';
import DashboardPage from '~/pages/Dashboard';
import SettingsPage from '~/pages/Settings';
import Layout from '~/components/Layout';
import authProvider from '~/utils/authProvider';
import dataProvider from '~/utils/dataProvider';
import queryClient from '~/utils/queryClient';
import theme from '~/utils/theme';

const App = () => {
  const store = memoryStore();

  return (
    <BrowserRouter>
      <Admin
        disableTelemetry
        dataProvider={dataProvider}
        authProvider={authProvider}
        dashboard={DashboardPage}
        queryClient={queryClient}
        store={store}
        layout={Layout}
        title="Shake App Admin Area"
        theme={theme}
      >
        <Resource {...Users} />
        <Resource {...Ambassadors} />
        <Resource {...Prizes} />
        <Resource {...Events} />
        <Resource {...Answers} />
        <Resource {...Reports} />
        <Resource {...SupportMessages} />
        <Resource {...UserVerifications} />
        <Resource {...Screenshots} />
        <Resource name="users/invited" />
        <Resource name="admin-panel/dating" />
        <Resource name="admin-panel/dating-responses" />
        <Resource name="admin-panel/events/ambassadors" />
        <CustomRoutes>
          <Route exact path="/settings" element={<SettingsPage />} />
        </CustomRoutes>
      </Admin>
    </BrowserRouter>
  );
};

export default hot(App);
