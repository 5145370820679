import React, { useState } from 'react';

const ShowMoreItems = ({ children, preDisplayedCount = 3 }) => {
  const [showMore, setShowMore] = useState(false);
  const length = children?.length || 0;
  const shouldShowMoreButton = length > preDisplayedCount;
  const noItems = length === 0;

  if (noItems) {
    return '-';
  }

  let items = children;

  if (shouldShowMoreButton) {
    items = showMore ? children : children.slice(0, preDisplayedCount);
  }

  return (
    <ul style={{ listStyleType: 'none' }}>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
      {shouldShowMoreButton && (
        <li key="showMore">
          <p style={{ fontWeight: 'bold' }} onClick={() => setShowMore(!showMore)}>
            {showMore ? 'Show less' : 'Show more'}
          </p>
        </li>
      )}
    </ul>
  );
};

export default ShowMoreItems;
