import React, { Fragment } from 'react';
import {
  Datagrid,
  Pagination,
  List,
  TextField,
  useRecordContext,
  FunctionField,
  SelectInput
} from 'react-admin';
import { useTheme } from '@mui/material/styles';

import useStyles from '~/components/styles';
import UserLinkField from '~/components/UserLinkField';
import ShowMoreItems from '~/components/ShowMoreItems';

import { EVENT_PARTICIPANT_STATES_MAP, EVENT_PARTICIPANT_STATES } from '../constants';
import EventParticipantStateSelector from './EventParticipantStateSelector';
import EventParticipantVIPStatusSelector from './EventParticipantVIPStatusSelector';
import SendMassMessagingButton from './SendMassMessagingButton';

const EventParticipantsBulkActionButtons = (props) => (
  <Fragment>
    <SendMassMessagingButton {...props} />
  </Fragment>
);

const filters = [
  <SelectInput
    key="state"
    source="state"
    label="State"
    choices={EVENT_PARTICIPANT_STATES}
    alwaysOn
  />
];

const EventParticipantsList = ({ children, dataGridOptions = {}, ...other }) => {
  const classes = useStyles();
  const { palette } = useTheme();
  const { id: eventId } = useRecordContext();

  const getBackgroundColor = (state) => {
    switch (state) {
      case EVENT_PARTICIPANT_STATES_MAP.APPROVED:
        return palette.success.main;

      case EVENT_PARTICIPANT_STATES_MAP.WAITLISTED_REJECTED:
        return palette.status.danger;

      case EVENT_PARTICIPANT_STATES_MAP.PENDING:
        return palette.warning.light;
    }
  };

  const rowStyle = (record) => ({
    backgroundColor: getBackgroundColor(record?.state)
  });

  const renderExpectedInvitedGuests = (record) => {
    const guests = record.expectedInvitedGuests || [];

    return <ShowMoreItems>{guests.map((item) => item)}</ShowMoreItems>;
  };

  const renderInvitedGuests = (record) => {
    const guests = record.invitedGuests || [];

    return (
      <ShowMoreItems>
        {guests.map((item, count) => (
          <UserLinkField
            key={item.id + count}
            idKey="id"
            nameKey="name"
            emptyText="-"
            data={item}
          />
        ))}
      </ShowMoreItems>
    );
  };

  return (
    <List
      className={classes.table}
      resource={'admin-panel/dating-responses'}
      title=" Participants"
      perPage={10}
      exporter={false}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      filter={{ eventId }}
      filters={filters}
      sort={{ field: 'createdAt', order: 'ASC' }}
      {...other}
    >
      <Datagrid
        rowStyle={rowStyle}
        bulkActionButtons={<EventParticipantsBulkActionButtons />}
        {...dataGridOptions}
      >
        <UserLinkField label="User" idKey="id" nameKey="name" emptyText="-" />
        <UserLinkField
          label="Invited to the event by (using event combo code)"
          idKey="invitingUserId"
          nameKey="invitingUserName"
          emptyText="-"
        />
        <TextField
          label="Invited to the event by (name, entered in the text input field on the web page)"
          source="expectedInvitedBy"
          sortable={false}
          emptyText="-"
        />
        <FunctionField
          label="Expected invited guests (names, entered in the input fields on the RSVP bottom sheet in the app)"
          render={renderExpectedInvitedGuests}
          emptyText="-"
        />
        <FunctionField
          label="Invited guests (persons, who used this user’s combo code)"
          render={renderInvitedGuests}
          emptyText="-"
        />
        <EventParticipantStateSelector label="State" eventId={eventId} />
        <EventParticipantVIPStatusSelector label="VIP Status" eventId={eventId} />
        {children}
      </Datagrid>
    </List>
  );
};

export default EventParticipantsList;
