import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const SingleSelect = ({
  value: currentValue,
  label: inputLabel,
  choices,
  valueKey = 'value',
  labelKey = 'label',
  className,
  size = 'medium',
  formControlProps = {},
  ...rest
}) => {
  const labelId = `${inputLabel}-single-select-label`;

  return (
    <FormControl fullWidth {...formControlProps} className={className} size={size}>
      <InputLabel id={labelId}>{inputLabel}</InputLabel>
      <Select labelId={labelId} value={currentValue} label={inputLabel} {...rest}>
        {choices.map((item) => (
          <MenuItem key={item[valueKey]} value={item[valueKey]}>
            {item[labelKey]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SingleSelect;
