import { withAccessChecking } from '~/utils/helpers';

import Show from './core/Show';
import Edit from './core/Edit';

export default {
  name: 'user-profiles/answers',
  show: withAccessChecking(Show),
  edit: withAccessChecking(Edit)
};
