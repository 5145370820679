import React from 'react';
import { Button, useRefresh, useNotify, useRecordContext, useResourceContext } from 'react-admin';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useHttp } from '~/utils/dataProvider';
import { CUSTOM_ENDPOINTS, REQUEST_TYPES } from '~/utils/constants';

export default (props) => {
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const disabled = !record?.id || record?.reviewed;
  const { id } = record || {};
  const refresh = useRefresh();
  const notify = useNotify();
  const [{ loading }, review] = useHttp(
    CUSTOM_ENDPOINTS.reviewedResourceById({ resource, id }),
    REQUEST_TYPES.PUT,
    {
      onSuccess: () =>
        notify('Reviewed successfully', {
          type: 'success'
        }),
      onError: (e) => notify(e.message, { type: 'error' })
    }
  );

  const onClick = async (e) => {
    e.stopPropagation();

    await review();
    refresh();
  };

  return (
    <Button label="Review" disabled={loading || disabled} onClick={onClick} size="small" {...props}>
      <VisibilityIcon />
    </Button>
  );
};
