import EventIcon from '@mui/icons-material/Event';

import { withAccessChecking } from '~/utils/helpers';

import List from './core/List';
import Edit from './core/Edit';
import Show from './core/Show';
import Create from './core/Create';

export default {
  name: 'admin-panel/events',
  list: withAccessChecking(List),
  edit: withAccessChecking(Edit),
  show: withAccessChecking(Show),
  create: withAccessChecking(Create),
  icon: EventIcon,
  options: {
    label: 'Events'
  }
};
