import ScreenshotIcon from '@mui/icons-material/Screenshot';

import { withAccessChecking } from '~/utils/helpers';

import List from './core/List';

export default {
  name: 'screenshots',
  list: withAccessChecking(List),
  icon: ScreenshotIcon,
  options: {
    label: 'Who takes screenshots'
  }
};
