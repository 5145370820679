import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { Controller } from 'react-hook-form';

export default ({ control, name, style, ...otherProps }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            labelPlacement="start"
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 8,
              ...style
            }}
            {...otherProps}
          />
        );
      }}
    />
  );
};
