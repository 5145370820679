import React, { Fragment, useState } from 'react';
import { useUpdateMany, useNotify, useUnselectAll, useRefresh, Confirm, Button } from 'react-admin';
import UpdateIcon from '@mui/icons-material/Update';

import SingleSelect from '~/components/Form/SingleSelect';

import { USER_ROLES_MAP, USER_ROLES } from '../constants';
import useStyles from '../styles';

const ChangeRoleButton = ({ selectedIds }) => {
  const classes = useStyles();
  const [role, setRole] = useState(USER_ROLES_MAP.USER);
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('users');
  const onSuccess = () => {
    refresh();
    notify('Users role updated');
    unselectAll();
  };
  const onError = () => notify('Error: users not updated', { type: 'warning' });
  const [updateMany, { isLoading }] = useUpdateMany(
    'users',
    {
      ids: selectedIds,
      data: { role }
    },
    { onSuccess, onError }
  );

  const handleChange = (e) => setRole(e.target.value);
  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };

  return (
    <Fragment>
      <Button label="Update User Role" onClick={handleClick} color="secondary">
        <UpdateIcon />
      </Button>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Update User Role"
        content={
          <SingleSelect
            value={role}
            label="Role"
            onChange={handleChange}
            className={classes.usersSelect}
            size="small"
            choices={USER_ROLES}
            valueKey="id"
            labelKey="name"
          />
        }
        onConfirm={handleConfirm}
        onClose={handleClose}
      />
    </Fragment>
  );
};

export default ChangeRoleButton;
