import React from 'react';
import { TextField, Datagrid, Pagination, List } from 'react-admin';

import Empty from '../EmptyList';
import UserLinkField from '../UserLinkField';
import useStyles from '../styles';

export default ({ children, dataGridOptions = {}, emptyText = 'No invited users', ...other }) => {
  const classes = useStyles();

  return (
    <List
      className={classes.table}
      resource="users/invited"
      title=" Invited users"
      perPage={10}
      exporter={false}
      pagination={<Pagination />}
      sort={{ field: 'id', order: 'DESC' }}
      empty={<Empty text={emptyText} />}
      {...other}
    >
      <Datagrid bulkActionButtons={false} {...dataGridOptions}>
        <UserLinkField idKey="id" nameKey="name" label="User" />
        <TextField source="gender" label="Gender" sortable={false} />
        {children}
      </Datagrid>
    </List>
  );
};
