import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput } from 'react-admin';
import pick from 'lodash/pick';

import CustomToolbar from '~/components/CustomToolbar';
import { USER_VERIFY_STATUSES, USER_VERIFY_STATUSES_MAP } from '~/resources/constants';

const transform = (data) => {
  return pick(data, ['status', 'comment']);
};

const userVerifyStatusValidator = (value) =>
  value === USER_VERIFY_STATUSES_MAP.PENDING
    ? `Status should be "${USER_VERIFY_STATUSES_MAP.APPROVED}" or "${USER_VERIFY_STATUSES_MAP.REJECTED}"`
    : undefined;

const EditUserVerification = (props) => {
  return (
    <Edit mutationMode="pessimistic" transform={transform} {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <div style={{ width: 200 }}>
          <TextInput source="comment" label="Comment" fullWidth />
          <SelectInput
            source="status"
            choices={USER_VERIFY_STATUSES}
            validate={userVerifyStatusValidator}
            fullWidth
          />
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default EditUserVerification;
