import React from 'react';
import {
  TextField,
  Datagrid,
  Pagination,
  List,
  BooleanField,
  useShowContext,
  EditButton
} from 'react-admin';

import Empty from '~/components/EmptyList';
import AnswerValuesField from '~/components/AnswerValuesField';
import QuestionTypeField from '~/components/QuestionTypeField';

const ReportsList = ({ children, dataGridOptions = {}, ...other }) => {
  const {
    record: { id }
  } = useShowContext();

  return (
    <List
      resource="user-profiles/answers"
      title=" Answers"
      perPage={25}
      exporter={false}
      pagination={<Pagination />}
      empty={<Empty text="No answers" />}
      filterDefaultValues={{ userId: id }}
      sort={{ field: 'key', order: 'DESC' }}
      {...other}
    >
      <Datagrid rowClick="show" bulkActionButtons={false} {...dataGridOptions}>
        <TextField source="key" label="Key" />
        <TextField source="title" label="Title" />
        <BooleanField source="visible" title="Visible" sortable={false} />
        <AnswerValuesField source="value" label="Values" sortable={false} />
        <QuestionTypeField source="type" label="Type" />
        {children}
        <EditButton label="Edit" />
      </Datagrid>
    </List>
  );
};

export default ReportsList;
