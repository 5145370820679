import React from 'react';
import { FunctionField } from 'react-admin';
import { Divider, Avatar, Stack, Link, Badge } from '@mui/material';

import { getSocialIconFromKey } from '../helpers';

const SocialMediaAvatarsField = (props) => {
  const renderSocialAvatars = (record) => {
    const socialMediaLinks = record?.socialMediaLinks ?? [];
    const notEmptySocialMediaLinks = socialMediaLinks.filter(({ username, link }) => {
      if (username === null && link === null) {
        return false;
      }

      return true;
    });

    if (notEmptySocialMediaLinks.length === 0) {
      return '-';
    }

    return (
      <Stack direction="column" spacing={1} divider={<Divider />}>
        {notEmptySocialMediaLinks.map(({ username, link, avatar, key }) => {
          const badgeContent = getSocialIconFromKey(key);

          return (
            <Stack direction="row" spacing={1} key={key} alignItems="center">
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={badgeContent}
              >
                <Avatar alt={username} src={avatar} />
              </Badge>

              <Link
                href={link}
                underline={link === null ? 'none' : 'always'}
                target="_blank"
                rel="noreferrer"
              >
                {username ?? key}
              </Link>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  return <FunctionField {...props} render={renderSocialAvatars} />;
};

export default SocialMediaAvatarsField;
