import React, { useState, Fragment } from 'react';
import { sanitizeFieldRestProps, useRecordContext } from 'react-admin';
import get from 'lodash/get';
import Typography from '@mui/material/Typography';

import useStyles from './styles';

const FullScreenImage = (props) => {
  const { previewImageClassName, fullImageClassName, emptyText, src, preview, title, ...rest } =
    props;
  const classes = useStyles();
  const record = useRecordContext(props);
  const [isFull, setIsFull] = useState(false);
  const sourceValue = get(record, src) || src;
  const titleValue = get(record, title) || title;
  const previewValue = get(record, preview) || preview;

  if (!sourceValue || !previewValue) {
    return emptyText ? (
      <Typography component="span" variant="body2" {...sanitizeFieldRestProps(rest)}>
        {emptyText}
      </Typography>
    ) : (
      <div {...sanitizeFieldRestProps(rest)} />
    );
  }

  const toggle = (e) => {
    e.stopPropagation();
    setIsFull((prevValue) => !prevValue);
  };

  return (
    <Fragment>
      {isFull && (
        <div onClick={toggle} className={classes.fullImageWrapper}>
          <img
            title={titleValue}
            alt={titleValue}
            src={sourceValue}
            className={`${classes.fullImage} ${fullImageClassName}`}
            {...sanitizeFieldRestProps(rest)}
          />
        </div>
      )}
      <img
        onClick={toggle}
        title={titleValue}
        alt={titleValue}
        src={previewValue}
        className={`${classes.previewImage} ${previewImageClassName}`}
        {...sanitizeFieldRestProps(rest)}
      />
    </Fragment>
  );
};

export default FullScreenImage;
