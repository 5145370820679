import React from 'react';
import {
  Show,
  TextField,
  DateField,
  SimpleShowLayout,
  BooleanField,
  TopToolbar
} from 'react-admin';

import UserLinkField from '~/components/UserLinkField';
import ReviewedButton from '~/components/ReviewedButton';

const Actions = ({ data, resource }) => {
  return (
    <TopToolbar>
      <ReviewedButton record={data} resource={resource} />
    </TopToolbar>
  );
};

export default () => (
  <Show actions={<Actions />}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <DateField source="createdAt" label="Created at" locales="en-EN" showTime />
      <DateField source="updatedAt" label="Updated at" locales="en-EN" showTime />
      <TextField source="text" label="Text" emptyText="-" />
      <TextField source="reason" label="Reason" />
      <TextField source="type" label="Type" />
      <TextField source="datingId" label="Dating ID" emptyText="-" />
      <BooleanField source="reviewed" label="Reviewed" />
      <UserLinkField label="User" />
      <UserLinkField label="Reporter" idKey="reporterId" nameKey="reporterName" />
    </SimpleShowLayout>
  </Show>
);
