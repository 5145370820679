import React from 'react';
import { Edit, BooleanInput } from 'react-admin';

import CreateEditEventForm, { defaulTransformCb } from '../components/CreateEditEventForm';

const editTransformCb = (values) => {
  const { invitingClosed } = values;
  const formData = defaulTransformCb(values);

  formData.set('invitingClosed', invitingClosed);

  return formData;
};

export default (props) => {
  return (
    <Edit mutationMode="pessimistic" transform={editTransformCb} {...props}>
      <CreateEditEventForm>
        <BooleanInput source="invitingClosed" label="Closed" />
      </CreateEditEventForm>
    </Edit>
  );
};
