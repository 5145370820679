import React from 'react';
import { useRecordContext, SelectInput, TextInput, ArrayInput } from 'react-admin';

import { QUESTION_TYPES_ENUM, QUESTION_TYPES_MAP } from '~/components/QuestionTypeField';

import { multipleTagsValidator } from '../utils';
import TagsValueInput from './TagsValueInput';

const ValueInput = () => {
  const record = useRecordContext();
  const typeKey = record.type;
  const type = QUESTION_TYPES_ENUM[typeKey];
  const parse = (v) => [[v]];
  const format = (v) => {
    if (v.length === 0 || v[0].length === 0) {
      return null;
    }

    return v[0][0];
  };

  if (type === QUESTION_TYPES_MAP.selector) {
    const choices = record.selectData.items ?? [];

    if (choices.length === 0) {
      return null;
    }

    return (
      <SelectInput
        source="value"
        format={format}
        parse={parse}
        choices={choices}
        emptyText="-"
        optionText="label"
        optionValue="value"
        required
      />
    );
  }

  if (type === QUESTION_TYPES_MAP.input || type === QUESTION_TYPES_MAP.locationAutocomplete) {
    return <TextInput source="value" format={format} parse={parse} required />;
  }

  if (type === QUESTION_TYPES_MAP.tags) {
    const data = record.tagsData;

    if (data.length === 0) {
      return null;
    }

    return (
      <ArrayInput source="value" record={record} validate={multipleTagsValidator}>
        <TagsValueInput />
      </ArrayInput>
    );
  }

  return null;
};

export default ValueInput;
