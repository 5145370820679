import React from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

import { formatDate } from '~/utils/helpers';

export default ({ control, name, helperText, inputLabelProps = {}, ...otherProps }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { value, ...otherFieldProps } = field;

        const formattedValue = formatDate(value, 'yyyy-MM-dd hh:mm').replace(' ', 'T');
        const marginBottom = helperText ? '16px' : '36px';

        return (
          <TextField
            {...otherFieldProps}
            size="small"
            type="datetime-local"
            style={{ margin: `0 0 ${marginBottom} 0` }}
            fullWidth
            {...otherProps}
            value={formattedValue}
            InputLabelProps={{
              shrink: true,
              ...inputLabelProps
            }}
          />
        );
      }}
    />
  );
};
