import { httpClient } from '~/utils/dataProvider';
import { STORAGE_KEY_ACCESS_TOKEN, STORAGE_KEY_REFRESH_TOKEN } from '~/utils/constants';

export default async () => {
  try {
    const { json } = await httpClient(
      '/auth/ap-refresh',
      {
        method: 'POST'
      },
      true
    );

    const { refreshToken: newRefreshToken, accessToken } = json ?? {};

    if (!newRefreshToken || !accessToken) {
      return false;
    }

    localStorage.setItem(STORAGE_KEY_ACCESS_TOKEN, accessToken);
    localStorage.setItem(STORAGE_KEY_REFRESH_TOKEN, newRefreshToken);

    return true;
  } catch (err) {
    console.error(err);

    return false;
  }
};
