import React from 'react';
import { TextInput } from 'react-admin';

import ReportsList, { defaultFilters } from '~/components/ReportsList';
import UserLinkField from '~/components/UserLinkField';

const filters = [
  <TextInput key="reporterId" source="reporterId" label="Reporter ID" resettable />,
  ...defaultFilters
];

export default (props) => {
  return (
    <ReportsList {...props} filters={filters}>
      <UserLinkField label="Reporter" idKey="reporterId" nameKey="reporterName" />
    </ReportsList>
  );
};
