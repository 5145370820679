import { useState, useCallback, useEffect } from 'react';
import { fetchUtils, HttpError } from 'react-admin';

import {
  STORAGE_KEY_ACCESS_TOKEN,
  STORAGE_KEY_REFRESH_TOKEN,
  ENVS,
  REQUEST_TYPES
} from '~/utils/constants';

function isNotJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return true;
  }
  return false;
}

const fetchJson = (url, options = {}) => {
  const requestHeaders = fetchUtils.createHeadersFromOptions(options);

  return fetch(url, { ...options, headers: requestHeaders })
    .then((response) =>
      response.text().then((text) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text
      }))
    )
    .then(({ status, headers, body }) => {
      let json;

      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        console.log(json);
        const defaultError = 'Something went wrong';
        const msg = json?.message;
        const errorMsg =
          (Array.isArray(msg) ? msg[0] : msg) ?? json?.error ?? json?.info ?? defaultError;

        return Promise.reject(new HttpError(errorMsg, status, json));
      }
      return Promise.resolve({ status, headers, body, json });
    });
};

export const httpClient = (url, options = {}, refreshToken = false) => {
  let token = '';
  const key = refreshToken ? STORAGE_KEY_REFRESH_TOKEN : STORAGE_KEY_ACCESS_TOKEN;

  try {
    token = localStorage.getItem(key);
  } catch (error) {
    console.error(error);
  }

  options.user = {
    authenticated: true,
    token: `Bearer ${token}`
  };

  const { body } = options;
  const isFormData = body instanceof FormData;

  if (body && isNotJson(body) && !isFormData) {
    options.body = JSON.stringify(body);
  }

  const dev = process.env.NODE_ENV === ENVS.development;
  const stage = process.env.NODE_ENV === ENVS.stage;
  const baseUrl = dev ? process.env.DEV_API : stage ? process.env.STAGE_API : process.env.PROD_API;
  const fullUrl = baseUrl + url;

  return fetchJson(fullUrl, options);
};

export const useHttp = (url, method, { lazy = false, onSuccess, onError } = {}) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const request = useCallback(
    async (options) => {
      try {
        setLoading(true);

        const res = await httpClient(url, { ...options, method });

        onSuccess?.(res);

        setData(res.json);
      } catch (err) {
        onError?.(err);

        setError(err);
      } finally {
        setLoading(false);
      }
    },
    [url, method, onSuccess, onError]
  );

  useEffect(() => {
    if (method !== REQUEST_TYPES.GET || lazy) {
      return;
    }

    request();
  }, [request, method, lazy]);

  return [
    {
      data,
      loading,
      error
    },
    request
  ];
};
