import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Title } from 'react-admin';

const DashboardPage = () => {
  return (
    <div>
      <Helmet>
        <title>Shake App Admin Panel</title>
      </Helmet>
      <Card>
        <Title title="Dashboard" />
        <CardHeader title="Dashboard" />
        <CardContent>Welcome to the Shake dating app admin panel</CardContent>
      </Card>
    </div>
  );
};

export default DashboardPage;
