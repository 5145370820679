export const required = (value) => (value ? undefined : 'Required input');

export const validateCoordinates = (value) => {
  const coordinates = value.split(',');

  if (coordinates.length !== 2) {
    return 'Invalid coordinates';
  }

  const lat = coordinates[0].trim();
  const long = coordinates[1].trim();
  const validLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/.test(lat);
  const validLon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/.test(long);

  if (validLat && validLon) {
    return undefined;
  }

  return 'Invalid coordinates';
};
