import React from 'react';
import { Grid } from '@mui/material';
import { Show, TextField, SimpleShowLayout } from 'react-admin';

import UserLinkField from '~/components/UserLinkField';
import FullScreenImage from '~/components/FullScreenImage';

const ShowUserVerification = (props) => {
  return (
    <Show {...props}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <SimpleShowLayout>
            <TextField source="id" label="ID" />
            <TextField source="comment" label="Comment" emptyText="-" />
            <TextField source="status" label="Status" />
            <UserLinkField label="User" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={12} md={4}>
          <SimpleShowLayout>
            <FullScreenImage src="firstPosePath" preview="firstPosePath" title="first pose image" />
            <FullScreenImage
              src="firstPhotoPath"
              preview="firstPhotoPath"
              title="first photo image"
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={12} md={4}>
          <SimpleShowLayout>
            <FullScreenImage
              src="secondPosePath"
              preview="secondPosePath"
              title="second pose image"
            />
            <FullScreenImage
              src="secondPhotoPath"
              preview="secondPhotoPath"
              title="second photo image"
            />
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </Show>
  );
};

export default ShowUserVerification;
