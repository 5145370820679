import React from 'react';
import Box from '@mui/material/Box';

import { SingleSelect, MultipleSelectChip } from '~/components/Form';

const TagsValueInput = (props) => {
  const {
    record: { tagsData },
    update,
    fields
  } = props;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {tagsData.map(({ tags, singleChoice }, index) => {
        const choices = tags ?? [];

        if (choices.length === 0) {
          return null;
        }

        const inputLabel = `Tag group - ${index + 1}`;

        if (singleChoice) {
          const currentValue = fields[index][0];

          return (
            <Box marginTop={index === 0 ? 4 : 0} marginBottom={2} key={index}>
              <SingleSelect
                value={currentValue}
                label={inputLabel}
                onChange={({ target: { value } }) => update(index, [value])}
                choices={choices}
              />
            </Box>
          );
        }

        const currentValue = fields[index];
        const currentValueArr = [];

        if (currentValue !== null && currentValue !== undefined) {
          for (const [key, value] of Object.entries(currentValue)) {
            if (key !== 'id') {
              currentValueArr.push(value);
            }
          }
        }

        return (
          <Box marginTop={index === 0 ? 4 : 0} marginBottom={2} key={index}>
            <MultipleSelectChip
              value={currentValueArr}
              label={inputLabel}
              onChange={({ target: { value } }) => update(index, value)}
              choices={choices}
            />
          </Box>
        );
      })}
    </div>
  );
};

export default TagsValueInput;
