import React from 'react';
import {
  TextField,
  DateField,
  Datagrid,
  Pagination,
  List,
  SearchInput,
  DateInput
} from 'react-admin';

import Empty from '../EmptyList';
import useStyles from '../styles';

const filters = [
  <SearchInput key="search" source="search" alwaysOn resettable />,
  <DateInput key="dateFrom" source="dateFrom" label="Date from" />,
  <DateInput key="dateTo" source="dateTo" label="Date to" />
];

export default ({ children, emptyText = 'No dates', ...other }) => {
  const classes = useStyles();

  return (
    <List
      className={classes.table}
      resource="admin-panel/dating"
      title=" Dates"
      perPage={10}
      exporter={false}
      pagination={<Pagination />}
      sort={{ field: 'date', order: 'DESC' }}
      filters={filters}
      empty={<Empty text={emptyText} />}
      {...other}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label="ID" emptyText="-" />
        <DateField source="date" label="Date" locales="en-EN" showTime emptyText="-" />
        <TextField source="title" label="Title" emptyText="-" />
        <TextField source="description" label="Description" emptyText="-" />
        <TextField source="type" label="Type" emptyText="-" />
        {children}
      </Datagrid>
    </List>
  );
};
