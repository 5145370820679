import React from 'react';
import { useRecordContext, useUpdate, useResourceContext, useNotify } from 'react-admin';
import Checkbox from '@mui/material/Checkbox';

function preventPropagation(e) {
  e.stopPropagation();
}

const EditCheckbox = (props) => {
  const {
    source,
    isDisabledByRecord = () => false,
    getUpdateData = () => {},
    getPreviousData = () => {}
  } = props;
  const notify = useNotify();
  const resource = useResourceContext(props);
  const record = useRecordContext(props) || {};
  const [update, { isLoading }] = useUpdate();
  const value = record?.[source] ?? 'false';
  const isDisabled =
    resource === null || record === null || isLoading || isDisabledByRecord(record);

  const onError = (e) => notify(e.message, { type: 'error' });
  const onSuccess = () => notify('Element updated', { type: 'success' });

  const onChange = (e) => {
    const targetValue = e.target.value === 'true';
    const newValue = !targetValue;

    update(
      resource,
      {
        id: record?.id,
        data: { [source]: newValue, ...getUpdateData(newValue, record) },
        previousData: { [source]: targetValue, ...getPreviousData(targetValue, record) }
      },
      {
        onSuccess: onSuccess,
        onError: onError
      }
    );
  };

  return (
    <Checkbox
      value={value}
      checked={value}
      disabled={isDisabled}
      onChange={onChange}
      onClick={preventPropagation}
    />
  );
};

export default EditCheckbox;
