import { makeStyles } from '@mui/styles';

export default makeStyles({
  actionButton: { marginLeft: '8px' },
  usersSelect: {
    minWidth: '160px',
    paddingBottom: 4,
    marginTop: 8
  },
  customFilter: {
    paddingBottom: 4
  },
  approvedFilter: {
    marginBottom: 6
  },
  selectWithoutMargin: {
    margin: 0
  }
});
