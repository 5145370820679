import React from 'react';
import { TextField, Datagrid, Pagination, List, useCreatePath, DateInput } from 'react-admin';

import FullScreenImage from '~/components/FullScreenImage';

const filters = [
  <DateInput key="dateFrom" source="dateFrom" label="Date From" resettable={true} />,
  <DateInput key="dateTo" source="dateTo" label="Date To" resettable={true} />
];

export default (props) => {
  const createPath = useCreatePath();
  const rowClick = (id) => createPath({ resource: 'users', type: 'show', id: id });

  return (
    <List
      {...props}
      title="Ambassadors"
      perPage={10}
      exporter={false}
      filters={filters}
      pagination={<Pagination />}
      sort={{ field: 'inviteCount', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false} rowClick={rowClick}>
        <TextField source="rank" label="Rank" sortable={false} />
        <TextField source="id" label="ID" sortable={false} />
        <TextField source="name" label="Name" sortable={false} />
        <FullScreenImage src="avatarPath" preview="avatarPath" title="Avatar" sortable={false} />
        <TextField source="inviteCount" label="Invite count" sortable={false} />
      </Datagrid>
    </List>
  );
};
