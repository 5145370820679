import React from 'react';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { AppBar as RaAppBar, UserMenu, useUserMenu, Logout } from 'react-admin';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import Typography from '@mui/material/Typography';

import useStyles from '../styles';

const ConfigurationMenu = forwardRef((props, ref) => {
  const { onClose } = useUserMenu();

  return (
    <MenuItem ref={ref} {...props} to="/settings" onClick={onClose} component={Link}>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText>Settings</ListItemText>
    </MenuItem>
  );
});

const CustomUserMenu = (props) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
    <Logout />
  </UserMenu>
);

const AppBar = (props) => {
  const classes = useStyles();

  return (
    <RaAppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.appBarTitle}
        id="react-admin-title"
      />
      <Typography variant="h6" color="inherit" className={classes.appBarTitle}>
        * {process.env.NODE_ENV.toUpperCase()} *
      </Typography>
    </RaAppBar>
  );
};

export default AppBar;
