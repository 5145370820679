import React, { Fragment } from 'react';
import { NumberField, FunctionField } from 'react-admin';

function AcceptedUsersField() {
  const render = () => (
    <Fragment>
      <NumberField source="attendeeCount" emptyText="0" />
      {' / '}
      <NumberField source="totalInvitesCount" emptyText="0" />
    </Fragment>
  );

  return <FunctionField render={render} />;
}

export default AcceptedUsersField;
