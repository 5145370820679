import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import { withAccessChecking } from '~/utils/helpers';

import List from './core/List';
import Show from './core/Show';
import Edit from './core/Edit';

export default {
  name: 'user-verifications',
  list: withAccessChecking(List),
  show: withAccessChecking(Show),
  edit: withAccessChecking(Edit),
  icon: VerifiedUserIcon,
  options: {
    label: 'User verifications'
  }
};
