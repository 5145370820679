import { makeStyles } from '@mui/styles';

export default makeStyles({
  table: { marginBottom: '16px' },
  singleFieldList: {
    marginBottom: 0,
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  appBarTitle: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
});
