import React from 'react';
import { Button, useRefresh, useNotify, useRecordContext } from 'react-admin';
import MmsIcon from '@mui/icons-material/Mms';

import { useHttp } from '~/utils/dataProvider';
import { CUSTOM_ENDPOINTS, REQUEST_TYPES } from '~/utils/constants';

const UserBlockMediaButton = (props) => {
  const record = useRecordContext(props);
  const { id, mediaMessagesBlocked, name } = record ?? {};
  const label = mediaMessagesBlocked ? 'Unblock media' : 'Block media';
  const refresh = useRefresh();
  const notify = useNotify();
  const [{ loading: blockLoading }, block] = useHttp(
    CUSTOM_ENDPOINTS.blockMedia({ id }),
    REQUEST_TYPES.POST,
    {
      onSuccess: () =>
        notify(`User ${name ?? ''} was successfully blocked from sending media files to the chat`, {
          type: 'success'
        }),
      onError: (e) => notify(e.message, { type: 'error' })
    }
  );
  const [{ loading: unblockLoading }, unblock] = useHttp(
    CUSTOM_ENDPOINTS.unblockMedia({ id }),
    REQUEST_TYPES.POST,
    {
      onSuccess: () =>
        notify(
          `User ${name ?? ''} was successfully unblocked from sending media files to the chat`,
          {
            type: 'success'
          }
        ),
      onError: (e) => notify(e.message, { type: 'error' })
    }
  );

  const loading = blockLoading || unblockLoading;
  const diabled = loading || !record;

  const onClick = async (e) => {
    e.stopPropagation();
    const action = mediaMessagesBlocked ? unblock : block;

    await action();
    refresh();
  };

  return (
    <Button label={label} disabled={diabled} onClick={onClick} size="small" {...props}>
      <MmsIcon />
    </Button>
  );
};

export default UserBlockMediaButton;
