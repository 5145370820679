import PeopleIcon from '@mui/icons-material/People';

import { withAccessChecking } from '~/utils/helpers';

import List from './core/List';
import Show from './core/Show';
import Edit from './core/Edit';

export default {
  name: 'users',
  list: withAccessChecking(List),
  show: withAccessChecking(Show),
  edit: withAccessChecking(Edit),
  icon: PeopleIcon,
  options: {
    label: 'Users'
  }
};
