import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#303030'
    },
    secondary: {
      main: '#1a73e8'
    },
    error: {
      main: '#D44D5C'
    },
    warning: {
      light: '#F9F1DC',
      main: '#FEDD72'
    },
    success: {
      main: '#E8FAEB'
    },
    status: {
      danger: '#FBE9E7',
      disabled: '#E5ECF4'
    }
  }
});
