import React from 'react';
import { useRecordContext } from 'react-admin';

import DatesList from '~/components/DatesList';
import UserLinkField from '~/components/UserLinkField';

const AppliedDatesTabContent = (props) => {
  const { id } = useRecordContext(props);

  return (
    <DatesList title=" Applied events" filter={{ invitedUserId: id }} emptyText="No applied events">
      <UserLinkField label="Author" />
    </DatesList>
  );
};

export default AppliedDatesTabContent;
