import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import { withAccessChecking } from '~/utils/helpers';

import List from './core/List';

export default {
  name: 'referrals/statistic',
  list: withAccessChecking(List),
  icon: SupervisedUserCircleIcon,
  options: {
    label: 'Ambassadors'
  }
};
