import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  NumberInput,
  DateInput,
  DeleteButton,
  ShowButton,
  TopToolbar,
  useRecordContext,
  FormDataConsumer
} from 'react-admin';
import pick from 'lodash/pick';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import CustomToolbar from '~/components/CustomToolbar';
import { validateCoordinates } from '~/utils/validators';

import {
  USER_GENDERS,
  USER_ROLES,
  LOOKING_FOR_GENDERS,
  PREMIUM_TYPES,
  USER_ROLES_MAP,
  USER_STATUS_KEY_MAP
} from '../constants';
import useStyles from '../styles';
import UserBlockMediaButton from '../components/UserBlockMediaButton';
import UserBannedButton from '../components/UserBannedButton';

const dateParser = (value) => {
  if (!value || value.length <= 0) {
    return '';
  }

  return new Date(value).toISOString();
};

const transform = (data) => {
  const requiredData = pick(data, [
    'email',
    'firstName',
    'lastName',
    'aboutMe',
    'invitingUserId',
    'lookingFor',
    'gender',
    'birthday',
    'role',
    'inviteLimit',
    'unlimitInvites',
    'minAge',
    'maxAge',
    'maxDistance',
    'coordinates',
    'premiumType',
    'score',
    's1Approved',
    's2Approved'
  ]);

  return requiredData;
};

const Actions = () => {
  const classes = useStyles();

  return (
    <TopToolbar>
      <UserBannedButton className={classes.actionButton} />
      <UserBlockMediaButton className={classes.actionButton} />
      <ShowButton className={classes.actionButton} />
      <DeleteButton className={classes.actionButton} />
    </TopToolbar>
  );
};

// eslint-disable-next-line no-unused-vars
const SanitizedGrid = ({ className, ...props }) => {
  return <Grid {...props} />;
};

const Form = () => {
  const classes = useStyles();
  const record = useRecordContext();
  const formContext = useFormContext();
  const approvedIsDisabled = record.status === USER_STATUS_KEY_MAP.NOT_COMPLITED;
  const onChangeRole = ({ target: { value } }) => {
    if (value === USER_ROLES_MAP.USER) {
      formContext.setValue('s1Approved', true);
      formContext.setValue('s2Approved', true);
      return;
    }

    if ([USER_ROLES_MAP.WAITLIST_USER, USER_ROLES_MAP.DECLINED].includes(value)) {
      formContext.setValue('s2Approved', record.s1Approved);
      formContext.setValue('s2Approved', false);
      return;
    }

    formContext.setValue('s1Approved', true);
    formContext.setValue('s2Approved', true);
  };

  return (
    <SanitizedGrid container spacing={2}>
      <Grid item xs={12} md={3} container direction="column">
        <SelectInput
          source="role"
          choices={USER_ROLES}
          className={classes.selectWithoutMargin}
          required
          onChange={onChangeRole}
        />
        <TextInput source="email" label="Email" />
        <TextInput source="firstName" label="First name" required />
        <TextInput source="lastName" label="Last name" />
        <TextInput source="aboutMe" label="About me info" />
      </Grid>
      <Grid item xs={12} md={3} container direction="column">
        <TextInput source="invitingUserId" label="Invited By (Enter a userId)" />
        <SelectInput
          source="lookingFor"
          choices={LOOKING_FOR_GENDERS}
          className={classes.selectWithoutMargin}
          required
        />
        <SelectInput
          source="gender"
          choices={USER_GENDERS}
          className={classes.selectWithoutMargin}
          required
        />
        <DateInput source="birthday" parse={dateParser} required />
        <NumberInput source="inviteLimit" label="Invite limit" required />
      </Grid>
      <Grid item xs={12} md={3} container direction="column">
        <NumberInput source="minAge" label="Min age" required />
        <NumberInput source="maxAge" label="Max age" required />
        <NumberInput source="maxDistance" label="Distance" required />
        <TextInput
          source="coordinates"
          label="Coordinates"
          required
          validate={(value) => validateCoordinates(value)}
        />
        <NumberInput source="score" label="Score" max={100} min={0} required />
      </Grid>
      <Grid item xs={12} md={3} container direction="column">
        <SelectInput
          source="premiumType"
          choices={PREMIUM_TYPES}
          className={classes.selectWithoutMargin}
          emptyText="None"
          emptyValue="none"
          required
        />
        <BooleanInput source="unlimitInvites" label="Unlimited invites" />
        <FormDataConsumer>
          {({ formData }) => {
            const disabled =
              approvedIsDisabled ||
              record.s1Approved ||
              formData.s2Approved ||
              formData.role !== USER_ROLES_MAP.WAITLIST_USER;

            return <BooleanInput source="s1Approved" label="S1 Approved" disabled={disabled} />;
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => {
            const disabled =
              approvedIsDisabled ||
              !formData.s1Approved ||
              (formData.role !== USER_ROLES_MAP.WAITLIST_USER &&
                formData.role !== USER_ROLES_MAP.USER);

            const onChange = ({ target: { checked } }) => {
              formContext.setValue(
                'role',
                checked ? USER_ROLES_MAP.USER : USER_ROLES_MAP.WAITLIST_USER
              );
            };

            return (
              <BooleanInput
                source="s2Approved"
                label="S2 Approved"
                disabled={disabled}
                onChange={onChange}
              />
            );
          }}
        </FormDataConsumer>
      </Grid>
    </SanitizedGrid>
  );
};

export default () => {
  return (
    <Edit mutationMode="pessimistic" transform={transform} actions={<Actions />}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <Form />
      </SimpleForm>
    </Edit>
  );
};
