import React from 'react';
import { Typography, Stack, Skeleton } from '@mui/material';

import { useHttp } from '~/utils/dataProvider/httpClient';
import { CUSTOM_ENDPOINTS, REQUEST_TYPES } from '~/utils/constants';

const AmbassadorInvitedUsersTotal = ({ userId }) => {
  const [{ data, loading }] = useHttp(
    CUSTOM_ENDPOINTS.getRevenueTotal({ userId }),
    REQUEST_TYPES.GET
  );

  if (loading) {
    return (
      <Stack direction="column" spacing={1} sx={{ mb: 1 }}>
        <Skeleton width={150} height={25} />
        <Skeleton width={170} height={25} />
        <Skeleton width={150} height={25} />
      </Stack>
    );
  }

  return (
    <Stack direction="column" spacing={1} sx={{ mb: 1 }}>
      <Typography level="body-xs">
        <b>Payout total:</b> ${data?.payoutTotal ?? '0'}
      </Typography>
      <Typography level="body-xs">
        <b>RevShare total:</b> ${data?.revShareTotal ?? '0'}
      </Typography>
      <Typography level="body-xs">
        <b>Revenue total:</b> ${data?.revenueTotal ?? '0'}
      </Typography>
    </Stack>
  );
};

export default AmbassadorInvitedUsersTotal;
