import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';

export default (props) => {
  const { invalid } = props;

  return (
    <Toolbar {...props}>
      <SaveButton disabled={invalid} />
    </Toolbar>
  );
};
