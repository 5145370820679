import React from 'react';
import Grid from '@mui/material/Grid';
import {
  SimpleShowLayout,
  TextField,
  DateField,
  EmailField,
  BooleanField,
  NumberField
} from 'react-admin';
import { useTheme } from '@mui/material/styles';

import UserLinkField from '~/components/UserLinkField';
import FullScreenImage from '~/components/FullScreenImage';

import SocialMediaAvatarsField from '../components/SocialMediaAvatarsField';
import AdditionalLinksField from '../components/AdditionalLinksField';

const SummaryTabContent = () => {
  const { palette } = useTheme();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={3}>
        <SimpleShowLayout>
          <DateField
            label="Deleted at"
            source="deletedAt"
            showTime
            locales="en-EN"
            emptyText="-"
            sx={{ color: palette.error.main }}
          />
          <FullScreenImage src="avatarPath" preview="avatarPath" title="Avatar" />
          <TextField label="ID" source="id" emptyText="-" />
          <TextField label="Phone" source="phone" emptyText="-" />
          <EmailField source="email" label="Email" emptyText="-" />
          <TextField label="First name" source="firstName" emptyText="-" />
          <TextField label="Last name" source="lastName" emptyText="-" />
          <TextField source="aboutMe" label="About" emptyText="-" />
          <DateField source="birthday" label="Birthday" locales="en-EN" emptyText="-" />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} md={3}>
        <SimpleShowLayout>
          <TextField source="gender" label="Gender" />
          <TextField label="Looking for" source="lookingFor" emptyText="-" />
          <TextField source="role" label="Role" emptyText="-" />
          <TextField source="coordinates" label="Coordinates" emptyText="-" />
          <TextField label="Country" source="country" emptyText="-" />
          <TextField label="State" source="state" emptyText="-" />
          <TextField label="City" source="city" emptyText="-" />
          <TextField label="Distance" source="distance" emptyText="-" />
          <BooleanField source="emailVerified" label="Email verified" />
          <BooleanField source="banned" label="Banned" />
          <BooleanField source="mediaMessagesBlocked" label="Media messages blocked" />
          <BooleanField source="isVerified" label="Is verified" />
          <TextField source="verificationState" label="Verification state" emptyText="-" />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} md={3}>
        <SimpleShowLayout>
          <BooleanField source="minProfileMet" label="Min profile met" />
          <BooleanField source="didCreateDate" label="Did create date" />
          <BooleanField source="didAcceptDate" label="Did accept date" />
          <TextField label="Invite limit" source="inviteLimit" emptyText="-" />
          <BooleanField label="Unlimited invites" source="unlimitInvites" />
          <TextField label="Facebook ID" source="facebookId" emptyText="-" />
          <TextField label="Google ID" source="googleId" emptyText="-" />
          <TextField label="Apple ID" source="appleId" emptyText="-" />
          <UserLinkField idKey="invitingUserId" nameKey="invitingUserName" label="Invited by" />
          <SocialMediaAvatarsField label="Social networks" />
          <AdditionalLinksField label="Additional links" />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} md={3}>
        <SimpleShowLayout>
          <TextField label="Link notes" source="linksInfo" emptyText="-" />
          <TextField label="Additional info" source="additionalInfo" emptyText="-" />
          <TextField label="Min age" source="minAge" emptyText="-" />
          <TextField label="Max age" source="maxAge" emptyText="-" />
          <TextField label="Distance" source="maxDistance" emptyText="-" />
          <TextField label="Premium type" source="premiumType" emptyText="-" />
          <TextField label="Score" source="score" emptyText="-" />
          <TextField label="Sign Up status" source="signUpStatus" emptyText="-" />
          <BooleanField label="S1 Approved" source="s1Approved" emptyText="-" />
          <BooleanField label="S2 Approved" source="s2Approved" emptyText="-" />
          <NumberField label="Tier" source="tier" emptyText="-" />
          <TextField source="signUpStep" label="Sign up step" emptyText="-" />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};

export default SummaryTabContent;
