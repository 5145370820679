import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextInput,
  TextField,
  BooleanField,
  SelectInput,
  EditButton,
  DateField,
  FunctionField,
  NumberField,
  BulkDeleteButton,
  useListContext
} from 'react-admin';
import omit from 'lodash/omit';
import { useTheme } from '@mui/material/styles';

import UserLinkField from '~/components/UserLinkField';
import { EditCheckbox } from '~/components/Edit';

import useStyles from '../styles';
import { USER_ROLES_MAP, USER_GENDERS, USER_ROLES, USER_STATUS_KEY_MAP } from '../constants';
import ChangePremiumTypeButton from '../components/ChangePremiumTypeButton';
import SendNotificationButton from '../components/SendNotificationButton';
import SocialMediaIconsField from '../components/SocialMediaIconsField';
import FilterWithSorting from '../components/FilterWithSorting';
import ChangeRoleButton from '../components/ChangeRoleButton';
import SignUpStepFilter from '../components/SignUpStepFilter';

const UsersBulkActionButtons = (props) => (
  <Fragment>
    <SendNotificationButton {...props} />
    <ChangePremiumTypeButton {...props} />
    <ChangeRoleButton {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);

const UsersListData = () => {
  const { palette } = useTheme();
  const { filterValues } = useListContext();
  const isWaitlist = filterValues.role === USER_ROLES_MAP.WAITLIST_USER;

  const renderSignInWithField = (data) => {
    if (data.googleId) {
      return 'Google';
    }

    if (data.appleId) {
      return 'Apple';
    }

    if (data.facebookId) {
      return 'Facebook';
    }

    return 'Phone';
  };

  const approvedIsDisabledByRecord = (record) =>
    [USER_ROLES_MAP.AMBASSADOR, USER_ROLES_MAP.TEST_USER, USER_ROLES_MAP.INTERNAL].includes(
      record.role
    ) || record.status === USER_STATUS_KEY_MAP.NOT_COMPLITED;

  const s1ApprovedIsDisabledByRecord = (record) =>
    approvedIsDisabledByRecord(record) || record.s1Approved;

  const s2ApprovedIsDisabledByRecord = (record) =>
    approvedIsDisabledByRecord(record) || !record.s1Approved;

  const rowStyle = (record) => {
    let backgroundColor;

    switch (record?.status) {
      case USER_STATUS_KEY_MAP.ACTIVE:
        backgroundColor = palette.success.main;
        break;
      case USER_STATUS_KEY_MAP.NOT_COMPLITED:
        backgroundColor = palette.status.danger;
        break;
      case USER_STATUS_KEY_MAP.PENDING:
        backgroundColor = palette.warning.light;
        break;
      case USER_STATUS_KEY_MAP.REJECTED:
        backgroundColor = palette.status.disabled;
        break;
      default:
        backgroundColor = null;
        break;
    }

    return { backgroundColor };
  };

  return (
    <Datagrid rowClick="show" bulkActionButtons={<UsersBulkActionButtons />} rowStyle={rowStyle}>
      <TextField source="id" label="ID" />
      {isWaitlist && <NumberField source="waitlistNumber" label="Waitlist rank" emptyText="-" />}
      <TextField source="role" label="Role" emptyText="-" />
      <TextField source="phone" label="Phone" emptyText="-" />
      <TextField source="firstName" label="First name" emptyText="-" />
      <TextField source="lastName" label="Last name" emptyText="-" />
      <TextField source="gender" label="Gender" emptyText="-" />
      <TextField source="premiumType" label="Premium type" emptyText="-" />
      <TextField source="score" label="Score" emptyText="-" />
      <TextField source="signUpStatus" label="Sign up status" emptyText="-" sortable={false} />
      <FunctionField label="Sign in with" render={renderSignInWithField} />
      <BooleanField source="banned" label="Banned" />
      <BooleanField source="minProfileMet" label="Min profile met" />
      <SocialMediaIconsField label="Social networks" />
      <UserLinkField idKey="invitingUserId" nameKey="invitingUserName" label="Invited by" />
      <EditCheckbox
        source="s1Approved"
        label="S1 approved"
        isDisabledByRecord={s1ApprovedIsDisabledByRecord}
      />
      <EditCheckbox
        source="s2Approved"
        label="S2 approved"
        isDisabledByRecord={s2ApprovedIsDisabledByRecord}
        getUpdateData={(newValue) => ({
          role: newValue ? USER_ROLES_MAP.USER : USER_ROLES_MAP.WAITLIST_USER
        })}
        getPreviousData={(value) => ({
          role: value ? USER_ROLES_MAP.USER : USER_ROLES_MAP.WAITLIST_USER
        })}
      />
      <DateField
        source="lastActivedAt"
        label="Last active at"
        locales="en-EN"
        showTime
        emptyText="-"
      />
      <DateField source="createdAt" label="Created at" locales="en-EN" showTime emptyText="-" />
      <EditButton label="Edit" />
    </Datagrid>
  );
};

const UsersList = () => {
  const classes = useStyles();

  const getSort = (filterValue) => {
    const isNotWaitlist = filterValue !== USER_ROLES_MAP.WAITLIST_USER;

    if (isNotWaitlist) {
      return { field: 'createdAt', order: 'DESC' };
    }

    return { field: 'waitlistNumber', order: 'ASC' };
  };

  const filterValuesConverter = (filterValues) => {
    const isNotWaitlist = filterValues['role'] !== USER_ROLES_MAP.WAITLIST_USER;

    if (isNotWaitlist) {
      return omit(filterValues, ['signUpStep']);
    }

    return filterValues;
  };

  const filters = [
    <TextInput
      key="search"
      source="search"
      label="Search"
      alwaysOn
      resettable
      className={classes.customFilter}
    />,
    <FilterWithSorting
      key="role"
      source="role"
      label="Role"
      choices={USER_ROLES}
      alwaysOn
      getSort={getSort}
      filterValuesConverter={filterValuesConverter}
    />,
    <SelectInput key="gender" source="gender" label="Gender" choices={USER_GENDERS} alwaysOn />,
    <SignUpStepFilter key="signUpStep" alwaysOn />
  ];

  return (
    <List
      title="Users"
      filters={filters}
      exporter={false}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <UsersListData />
    </List>
  );
};

export default UsersList;
