import React from 'react';
import { Edit } from 'react-admin';

import CreateEditPrizeForm, { transform } from '../components/CreateEditPrizeForm';

export default (props) => {
  return (
    <Edit mutationMode="pessimistic" transform={transform} {...props}>
      <CreateEditPrizeForm />
    </Edit>
  );
};
