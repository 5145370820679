export const multipleTagsValidator = (value) => {
  var hasEmptyGroup = false;

  value.forEach((el) => {
    if (el.length === 0) {
      hasEmptyGroup = true;
    }
  });

  if (hasEmptyGroup) {
    return 'Each group must have at least one item selected.';
  }

  return undefined;
};
