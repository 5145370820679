export const CUSTOM_ENDPOINTS = {
  getGlobalSettings: () => '/global-settings/admin-panel',
  updateGlobalSettings: () => '/global-settings',
  reviewedResourceById: ({ resource, id }) => `/${resource}/reviewed/${id}`,
  referralsMonthyPrizes: () => 'referrals/monthy-prizes',
  getRevenueTotal: ({ userId }) => `/referrals/revenue-total/${userId}`,
  sendTestNotification: () => '/user-devices/send-test-notification',
  unbanUser: ({ id }) => `/admin/users/unban/${id}`,
  banUser: ({ id }) => `/admin/users/ban/${id}`,
  unblockMedia: ({ id }) => `/admin/users/unblock-media/${id}`,
  blockMedia: ({ id }) => `/admin/users/block-media/${id}`,
  setFileState: ({ fileId }) => `/user-files/set-state/${fileId}`,
  setDatingResponsesState: () => '/admin-panel/dating-responses/set-state',
  setDatingResponsesVIPStatus: () => '/admin-panel/dating-responses/set-vip-status',
  sendMassMessageToEventParticipant: () => '/admin-panel/events/send-mass-message'
};

export const REQUEST_TYPES = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT'
};
