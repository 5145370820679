import React from 'react';
import { Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import { useListContext } from 'react-admin';

import useStyles from '../styles';

const defaultGetSort = () => ({ field: 'id', order: 'ASC' });

const defaultFilterValuesConverter = (filterValues) => filterValues;

const FilterWithSorting = ({
  source = '',
  label = '',
  choices = [],
  getSort = defaultGetSort,
  filterValuesConverter = defaultFilterValuesConverter
}) => {
  const classes = useStyles();
  const { setSort, setFilters, filterValues, displayedFilters } = useListContext();

  const handleChange = ({ target: { value } }) => {
    const newFilterValues = filterValuesConverter({ ...filterValues, [source]: value });

    setFilters(newFilterValues, displayedFilters, false);

    const sort = getSort(value);

    setSort(sort);
  };

  const getEmpty = () => ' ';

  return (
    <FormControl fullWidth className={classes.usersSelect} size="small">
      <InputLabel id={`${source}-select-label`}>{label}</InputLabel>
      <Select
        value={filterValues.role ?? ''}
        onChange={handleChange}
        label={label}
        labelId={`${source}-select-label`}
        id={`${source}-select`}
      >
        <MenuItem key="null" value="">
          {getEmpty()}
        </MenuItem>
        {choices.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterWithSorting;
