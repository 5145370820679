import React from 'react';
import { FunctionField } from 'react-admin';

import { getSocialIconFromKey } from '../helpers';

const SocialMediaIconsField = (props) => {
  const renderSocialLinks = (record) => {
    if (record.socialMediaLinks === null || record.socialMediaLinks.length === 0) {
      return '-';
    }

    return (
      <div style={{ display: 'flex' }}>
        {record.socialMediaLinks.map(({ username, link = '', key }) => {
          const icon = getSocialIconFromKey(key);

          return (
            <a
              title={username}
              key={key}
              href={link}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              {icon}
            </a>
          );
        })}
      </div>
    );
  };

  return <FunctionField {...props} render={renderSocialLinks} />;
};

export default SocialMediaIconsField;
