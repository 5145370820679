import React from 'react';
import { useRecordContext } from 'react-admin';

import ReportsList from '~/components/ReportsList';

const ReportsTabContent = (props) => {
  const { id } = useRecordContext(props);

  return <ReportsList filter={{ reporterId: id }} />;
};

export default ReportsTabContent;
