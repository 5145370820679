import HelpIcon from '@mui/icons-material/Help';

import { withAccessChecking } from '~/utils/helpers';

import List from './core/List';
import Show from './core/Show';

export default {
  name: 'supports',
  list: withAccessChecking(List),
  show: withAccessChecking(Show),
  icon: HelpIcon,
  options: {
    label: 'Support messages'
  }
};
