import React from 'react';
import {
  Show as RAShow,
  TextField,
  SimpleShowLayout,
  BooleanField,
  FunctionField,
  ArrayField,
  Datagrid,
  ListContextProvider
} from 'react-admin';

import QuestionTypeField, {
  QUESTION_TYPES_ENUM,
  QUESTION_TYPES_MAP
} from '~/components/QuestionTypeField';
import AnswerValuesField from '~/components/AnswerValuesField';

const QuestionDataField = () => {
  const render = (record) => {
    const typeKey = record.type;
    const type = QUESTION_TYPES_ENUM[typeKey];

    switch (type) {
      case QUESTION_TYPES_MAP.selector:
        return (
          <SimpleShowLayout record={record.selectData}>
            <TextField source="hint" label="Hint" />
            <ArrayField source="items" label="Items">
              <Datagrid bulkActionButtons={false}>
                <TextField source="value" label="Value" />
                <TextField source="label" label="Label" />
              </Datagrid>
            </ArrayField>
          </SimpleShowLayout>
        );
      case QUESTION_TYPES_MAP.input:
        return (
          <SimpleShowLayout record={record.inputData}>
            <TextField source="hint" label="Hint" />
          </SimpleShowLayout>
        );
      case QUESTION_TYPES_MAP.tags:
        return (
          <ListContextProvider
            value={{
              data: record.tagsData,
              total: record.tagsData.length,
              sort: { field: null, order: null }
            }}
          >
            <Datagrid bulkActionButtons={false}>
              <BooleanField source="singleChoice" title="Single choice" />
              <ArrayField source="tags" label="Tags">
                <Datagrid bulkActionButtons={false}>
                  <TextField source="value" label="Value" />
                  <TextField source="label" label="Label" />
                </Datagrid>
              </ArrayField>
            </Datagrid>
          </ListContextProvider>
        );
      case QUESTION_TYPES_MAP.locationAutocomplete:
        return null;
      default:
        return '-';
    }
  };

  return <FunctionField label="Data" render={render} />;
};

const Show = (props) => {
  return (
    <RAShow {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <TextField source="key" label="Question key" sortable={false} />
        <TextField source="title" label="Title" />
        <BooleanField source="visible" title="Visible" sortable={false} />
        <AnswerValuesField source="value" label="Values" sortable={false} />
        <QuestionTypeField source="type" label="Type" />
        <QuestionDataField />
      </SimpleShowLayout>
    </RAShow>
  );
};

export default Show;
