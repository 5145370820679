import React, { Fragment } from 'react';
import { Layout } from 'react-admin';
import { ReactQueryDevtools } from 'react-query/devtools';

import { useRefreshToken } from '~/utils/authProvider';

import AppBar from './AppBar';
import Menu from './Menu';

export default (props) => {
  useRefreshToken();

  return (
    <Fragment>
      <Layout {...props} menu={Menu} appBar={AppBar} />
      <ReactQueryDevtools initialIsOpen={false} />
    </Fragment>
  );
};
