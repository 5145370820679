import React from 'react';
import { Card, CardHeader, Typography, LinearProgress } from '@mui/material';
import { Title } from 'react-admin';

import { useHttp } from '~/utils/dataProvider';
import { CUSTOM_ENDPOINTS, REQUEST_TYPES } from '~/utils/constants';

import Form from './components/Form';

const Content = () => {
  const [{ data, loading, error }] = useHttp(
    CUSTOM_ENDPOINTS.getGlobalSettings(),
    REQUEST_TYPES.GET
  );

  if (error) {
    return <Typography variant="p">{error}</Typography>;
  }

  if (loading || !data) {
    return <LinearProgress />;
  }

  return <Form defaultValues={data} />;
};

export default () => {
  return (
    <Card>
      <Title title="Settings" />
      <CardHeader title="Global Settings" />
      <Content />
    </Card>
  );
};
