import React from 'react';
import { FunctionField } from 'react-admin';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const AnswerValuesField = ({ source, ...other }) => {
  const render = (record) => {
    const values = record[source] ?? [];

    if (values === null || values.length === 0) {
      return '-';
    }

    return (
      <Stack direction="column" spacing={1}>
        {values.map((group, idx) => (
          <Stack key={idx} direction="row" spacing={1}>
            {group.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Stack>
        ))}
      </Stack>
    );
  };

  return <FunctionField {...other} render={render} />;
};

export default AnswerValuesField;
