import React from 'react';
import {
  TextField,
  DateField,
  Datagrid,
  Pagination,
  List,
  EditButton,
  ArrayField,
  SingleFieldList,
  BooleanField
} from 'react-admin';

import FullScreenImage, { useFullScreenImageStyles } from '~/components/FullScreenImage';
import useComponentsStyles from '~/components/styles';

import AcceptedUsersField from '../components/AcceptedUsersField';
import TagsField from '../components/TagsField';

export default (props) => {
  const fullScreenImageClasses = useFullScreenImageStyles();
  const componentsClasses = useComponentsStyles();

  return (
    <List
      {...props}
      title="Events"
      perPage={10}
      exporter={false}
      pagination={<Pagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid rowClick="show" bulkActionButtons={true}>
        <TextField source="id" label="ID" />
        <TextField source="title" label="Title" />
        <TextField source="description" label="Description" />
        <TextField source="city" label="City" />
        <TextField source="address" label="Address" />
        <TextField source="visibility" label="Visibility" />
        <ArrayField source="files">
          <SingleFieldList linkType={false} className={componentsClasses.singleFieldList}>
            <FullScreenImage
              src="filePath"
              preview="previewPath"
              title="Event image"
              previewImageClassName={fullScreenImageClasses.verticalPreviewImage}
              fullImageClassName={fullScreenImageClasses.verticalFullImage}
            />
          </SingleFieldList>
        </ArrayField>
        <TagsField label="Tags" />
        <DateField source="date" label="Date time" locales="en-EN" showTime />
        <DateField source="createdAt" label="Created at" locales="en-EN" />
        <BooleanField source="invitingClosed" label="Closed" />
        <BooleanField source="allowExtraPersons" label="Allow extra persons" />
        <AcceptedUsersField label="Accepted users" />
        <EditButton label="Edit" />
      </Datagrid>
    </List>
  );
};
