import React from 'react';
import {
  Datagrid,
  Pagination,
  List,
  ChipField,
  useRecordContext,
  FunctionField
} from 'react-admin';

import useStyles from '~/components/styles';
import UserLinkField from '~/components/UserLinkField';

const AmbassadorsList = ({ children, dataGridOptions = {}, ...other }) => {
  const classes = useStyles();
  const { id } = useRecordContext();

  const render = ({ link }) =>
    link === null || link === '' ? (
      '-'
    ) : (
      <a href={link} rel="noreferrer" target="_blank">
        Link
      </a>
    );

  return (
    <List
      className={classes.table}
      resource="admin-panel/events/ambassadors"
      title=" Ambassadors"
      perPage={10}
      exporter={false}
      pagination={<Pagination />}
      filter={{ eventId: id }}
      {...other}
    >
      <Datagrid bulkActionButtons={false} {...dataGridOptions}>
        <UserLinkField label="User" idKey="id" nameKey="name" emptyText="-" />
        <ChipField source="code" label="Combo code" emptyText="-" />
        <FunctionField render={render} label="Link to event landing page" />
        {children}
      </Datagrid>
    </List>
  );
};

export default AmbassadorsList;
