import React from 'react';
import {
  TextField,
  DateField,
  Datagrid,
  Pagination,
  List,
  TextInput,
  ArrayField,
  SingleFieldList,
  BooleanField
} from 'react-admin';
import { useTheme } from '@mui/material/styles';

import FullScreenImage, { useFullScreenImageStyles } from '~/components/FullScreenImage';

import ReviewedButton from '../ReviewedButton';
import useStyles from '../styles';

export const defaultFilters = [
  <TextInput key="search" source="search" label="Search" alwaysOn resettable />
];

export default ({ dataGridOptions = {}, ...other }) => {
  const classes = useStyles();
  const { palette } = useTheme();
  const fullScreenImageClasses = useFullScreenImageStyles();

  const rowStyle = (record) => ({
    backgroundColor: record?.reviewed ? palette.success.main : ''
  });

  return (
    <List
      className={classes.table}
      resource="supports"
      title=" Support messages"
      perPage={10}
      exporter={false}
      pagination={<Pagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={defaultFilters}
      {...other}
    >
      <Datagrid rowStyle={rowStyle} rowClick="show" bulkActionButtons={false} {...dataGridOptions}>
        <TextField source="id" label="ID" />
        <DateField source="createdAt" label="Created at" locales="en-EN" showTime />
        <DateField source="updatedAt" label="Updated at" locales="en-EN" showTime />
        <TextField source="text" label="Text" />
        <BooleanField source="reviewed" label="Reviewed" />
        <ArrayField source="files">
          <SingleFieldList linkType={false} className={classes.singleFieldList}>
            <FullScreenImage
              src="filePath"
              preview="previewPath"
              title="support image"
              previewImageClassName={fullScreenImageClasses.verticalPreviewImage}
              fullImageClassName={fullScreenImageClasses.verticalFullImage}
            />
          </SingleFieldList>
        </ArrayField>
        <ReviewedButton label="Review" />
      </Datagrid>
    </List>
  );
};
