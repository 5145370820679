export default (map) => {
  return Object.entries(map).map(([key, value]) => {
    const splittedKey = key.split('_');
    const name = splittedKey.map((el) => el[0] + el.slice(1).toLowerCase()).join(' ');

    return {
      id: value,
      name
    };
  });
};
