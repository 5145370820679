import React from 'react';
import { Button, useRefresh, useNotify, useRecordContext } from 'react-admin';
import BlockIcon from '@mui/icons-material/Block';

import { useHttp } from '~/utils/dataProvider';
import { CUSTOM_ENDPOINTS, REQUEST_TYPES } from '~/utils/constants';

const UserBannedButton = (props) => {
  const record = useRecordContext(props);
  const { id, banned, name } = record ?? {};
  const label = banned ? 'unban' : 'ban';
  const refresh = useRefresh();
  const notify = useNotify();
  const [{ loading: banLoading }, ban] = useHttp(
    CUSTOM_ENDPOINTS.banUser({ id }),
    REQUEST_TYPES.POST,
    {
      onSuccess: () => notify(`User ${name ?? ''} successfully banned`, { type: 'success' }),
      onError: (e) => notify(e.message, { type: 'error' })
    }
  );
  const [{ loading: unbanLoading }, unban] = useHttp(
    CUSTOM_ENDPOINTS.unbanUser({ id }),
    REQUEST_TYPES.POST,
    {
      onSuccess: () => notify(`User ${name ?? ''} successfully unbanned`, { type: 'success' }),
      onError: (e) => notify(e.message, { type: 'error' })
    }
  );

  const loading = banLoading || unbanLoading;
  const disabled = loading || !record;

  const onClick = async (e) => {
    e.stopPropagation();
    const action = banned ? unban : ban;

    await action();
    refresh();
  };

  return (
    <Button label={label} disabled={disabled} onClick={onClick} size="small" {...props}>
      <BlockIcon />
    </Button>
  );
};

export default UserBannedButton;
