import React, { useState, Fragment } from 'react';
import { Button, Form, useListContext, useNotify, useUnselectAll, required } from 'react-admin';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { InputWithMaxCount } from '~/components/Form';
import { CUSTOM_ENDPOINTS, REQUEST_TYPES } from '~/utils/constants';
import { useHttp } from '~/utils/dataProvider';

const SendMassMessagingButton = () => {
  const { selectedIds } = useListContext();
  const [isOpen, setIsOpen] = useState(false);
  const notify = useNotify();
  const unselectAll = useUnselectAll('admin-panel/dating-responses');
  const [{ isLoading }, send] = useHttp(
    CUSTOM_ENDPOINTS.sendMassMessageToEventParticipant(),
    REQUEST_TYPES.POST,
    {
      onSuccess: () => {
        notify('Messages have been sent successfully', { type: 'success' });
        unselectAll();
        setIsOpen(false);
      },
      onError: (e) => notify(e.message, { type: 'error' })
    }
  );

  const handleOpenDialog = () => setIsOpen(true);
  const handleDialogClose = () => setIsOpen(false);
  const handleConfirm = ({ id: eventId, message }) => {
    send({ body: { eventId, message, users: selectedIds } });
  };

  return (
    <Fragment>
      <Button label="Send mass messaging" onClick={handleOpenDialog} color="secondary">
        <NotificationAddIcon />
      </Button>
      <Dialog onClose={handleDialogClose} open={isOpen} isLoading={isLoading}>
        <Form onSubmit={handleConfirm}>
          <DialogTitle>Send mass messaging</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <InputWithMaxCount
                sx={{ marginTop: '8px' }}
                source="message"
                validate={[required()]}
                multiline
                fullWidth
                maxRows={5}
                maxCount={320}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button label="Cancel" onClick={handleDialogClose} disabled={isLoading} />
            <Button label="Send" type="submit" disabled={isLoading} />
          </DialogActions>
        </Form>
      </Dialog>
    </Fragment>
  );
};

export default SendMassMessagingButton;
