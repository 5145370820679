import React from 'react';
import {
  SimpleForm,
  TextInput,
  DateTimeInput,
  ImageInput,
  ImageField,
  AutocompleteArrayInput,
  useRecordContext,
  SelectInput,
  NumberInput,
  BooleanInput
} from 'react-admin';

import CustomToolbar from '~/components/CustomToolbar';

import { EVENT_VISIBILITY } from '../constants';

export const defaulTransformCb = (values) => {
  const {
    title,
    description,
    address,
    city,
    date,
    visibility,
    tags,
    totalInvitesCount,
    allowExtraPersons,
    files: { rawFile }
  } = values;
  const formData = new FormData();
  const notEmptyTags = tags.filter((n) => n);

  formData.set('date', date);
  formData.set('city', city);
  formData.set('title', title);
  formData.set('address', address);
  formData.set('visibility', visibility);
  formData.set('description', description);
  formData.set('totalInvitesCount', totalInvitesCount);
  formData.set('allowExtraPersons', allowExtraPersons);

  if (notEmptyTags?.length > 0) {
    formData.set('tags', notEmptyTags);
  }

  if (rawFile !== null && rawFile !== undefined) {
    formData.append('files', rawFile);
  }

  return formData;
};

const dateTimeParser = (value) => {
  if (!value || value.length <= 0) {
    return '';
  }

  const parsedDate = new Date(value).toISOString();

  return parsedDate;
};

const imageValidator = (value) => {
  if (value === null || value === undefined) {
    return 'Please select at least one picture';
  }

  const fileSize = value?.rawFile?.size;

  if (fileSize === null) {
    return undefined;
  }

  const maxFileSizeInKB = 600 * 1000;

  if (fileSize > maxFileSizeInKB) {
    return 'File must be not larger than 600 KB';
  }

  return undefined;
};

const TagsInput = (props) => {
  const record = useRecordContext(props) || {};
  const currentTags = record.tags || [];
  const tagsChoices = currentTags.map((tag) => ({ id: tag, name: tag }));

  const onCreate = (value) => {
    const newCategory = { id: value, name: value };

    tagsChoices.push(newCategory);

    return newCategory;
  };

  return <AutocompleteArrayInput source="tags" choices={tagsChoices} onCreate={onCreate} />;
};

export default ({ children, ...otherProps }) => {
  return (
    <SimpleForm toolbar={<CustomToolbar />} {...otherProps}>
      <div style={{ width: 400 }}>
        {children}
        <BooleanInput
          defaultValue={false}
          source="allowExtraPersons"
          label="Allow invitees to bring guests"
        />
        <TextInput source="title" label="Title" required fullWidth />
        <TextInput
          source="description"
          label="Description (will be shown on the event web-page)"
          required
          multiline
          fullWidth
        />
        <TextInput source="city" label="City" required fullWidth />
        <TextInput source="address" label="Address" required fullWidth />
        <DateTimeInput
          source="date"
          label="Date"
          parse={dateTimeParser}
          defaultValue={new Date()}
          required
          fullWidth
        />
        <TagsInput />
        <SelectInput
          key="visibility"
          source="visibility"
          label="Visibility"
          required
          fullWidth
          choices={EVENT_VISIBILITY}
        />
        <NumberInput source="totalInvitesCount" label="Total invites count" required fullWidth />
        <ImageInput
          source="files"
          label="Event picture"
          accept="image/*"
          required
          multiple={false}
          validate={imageValidator}
          sx={{
            '& .MuiFormHelperText-root': {
              color: 'red'
            }
          }}
        >
          <ImageField
            source="filePath"
            sx={{
              '& .RaImageField-image': {
                width: '180px',
                height: 'auto',
                objectFit: 'cover',
                aspectRatio: '9/19.5'
              }
            }}
          />
        </ImageInput>
        <p>
          It is recommended to use PNG, GIF, JPEG files not larger than 600 KB to ensure correct MMS
          delivery.
        </p>
      </div>
    </SimpleForm>
  );
};
