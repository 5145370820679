import React from 'react';
import { TextField, DateField, Datagrid, Pagination, List, EditButton } from 'react-admin';

import FullScreenImage from '~/components/FullScreenImage';

export default (props) => {
  return (
    <List
      {...props}
      title="Prizes"
      perPage={10}
      exporter={false}
      pagination={<Pagination />}
      sort={{ field: 'drawingDate', order: 'ASC' }}
    >
      <Datagrid rowClick="edit" bulkActionButtons={true}>
        <TextField source="id" label="ID" />
        <TextField source="title" label="Title" />
        <FullScreenImage
          src="prizeImage.filePath"
          preview="prizeImage.filePath"
          title="Prize image"
        />
        <DateField source="drawingDate" label="Drawing date" locales="en-EN" showTime />
        <EditButton label="Edit" />
      </Datagrid>
    </List>
  );
};
