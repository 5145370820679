import React from 'react';
import { Create } from 'react-admin';

import CreateEditEventForm, { defaulTransformCb } from '../components/CreateEditEventForm';

export default (props) => {
  return (
    <Create transform={defaulTransformCb} redirect="list" {...props}>
      <CreateEditEventForm />
    </Create>
  );
};
