import React from 'react';
import {
  Show as RAShow,
  TextField,
  DateField,
  SimpleShowLayout,
  ArrayField,
  SingleFieldList,
  BooleanField,
  TopToolbar
} from 'react-admin';

import UserLinkField from '~/components/UserLinkField';
import ReviewedButton from '~/components/ReviewedButton';
import FullScreenImage, { useFullScreenImageStyles } from '~/components/FullScreenImage';
import useStyles from '~/components/styles';

const Actions = ({ data, resource }) => {
  return (
    <TopToolbar>
      <ReviewedButton record={data} resource={resource} />
    </TopToolbar>
  );
};

const Show = (props) => {
  const classes = useStyles();
  const fullScreenImageClasses = useFullScreenImageStyles();

  return (
    <RAShow {...props} actions={<Actions />}>
      <SimpleShowLayout>
        <TextField label="ID" source="id" />
        <TextField label="Text" source="text" />
        <DateField source="createdAt" label="Created at" locales="en-EN" showTime />
        <DateField source="updatedAt" label="Updated at" locales="en-EN" showTime />
        <BooleanField source="reviewed" label="Reviewed" />
        <UserLinkField label="User" />
        <ArrayField source="files">
          <SingleFieldList linkType={false} className={classes.singleFieldList}>
            <FullScreenImage
              src="filePath"
              preview="previewPath"
              title="support image"
              previewImageClassName={fullScreenImageClasses.verticalPreviewImage}
              fullImageClassName={fullScreenImageClasses.verticalFullImage}
            />
          </SingleFieldList>
        </ArrayField>
      </SimpleShowLayout>
    </RAShow>
  );
};

export default Show;
