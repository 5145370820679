import React from 'react';
import { Edit as RAEdit, SimpleForm, BooleanInput } from 'react-admin';
import pick from 'lodash/pick';

import CustomToolbar from '~/components/CustomToolbar';

import { ValueInput } from '../components';

const transform = (data) => {
  const pickedData = pick(data, ['value', 'visible']);

  return pickedData;
};

const Edit = (props) => {
  return (
    <RAEdit mutationMode="pessimistic" redirect="show" transform={transform} {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <BooleanInput label="Visible" source="visible" />
        <ValueInput />
      </SimpleForm>
    </RAEdit>
  );
};

export default Edit;
