import React, { Fragment, useState } from 'react';
import { useNotify, useUnselectAll, useRefresh, Confirm, Button } from 'react-admin';
import { FormControl, InputLabel, OutlinedInput, Box } from '@mui/material';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';

import SingleSelect from '~/components/Form/SingleSelect';
import { useHttp } from '~/utils/dataProvider';
import { CUSTOM_ENDPOINTS, REQUEST_TYPES } from '~/utils/constants';

import {
  NOTIFICATION_TYPES_MAP,
  NOTIFICATION_TYPES,
  NOTIFICATION_DATA_KEY_MAP
} from '../constants';

const SendNotificationButton = ({ selectedIds }) => {
  const {
    text,
    setText,
    title,
    setTitle,
    open,
    setOpen,
    data,
    loading,
    handleChangeData,
    handleConfirm
  } = useData(selectedIds);

  return (
    <Fragment>
      <Button label="Send notification" onClick={() => setOpen(true)} color="secondary">
        <NotificationAddIcon />
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title="Send notification"
        content={
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <AppOutlinedInput
              value={title}
              setValue={(e) => setTitle(e.target.value)}
              id="title-input"
              label="Title"
            />
            <AppOutlinedInput
              value={text}
              setValue={(e) => setText(e.target.value)}
              id="text-input"
              label="Text"
            />
            <TypeSelector
              value={data.type}
              setValue={handleChangeData(NOTIFICATION_DATA_KEY_MAP.TYPE)}
            />
            {data.type === NOTIFICATION_TYPES_MAP.POST_SCREEN && (
              <AppOutlinedInput
                value={data.dateId}
                setValue={handleChangeData(NOTIFICATION_DATA_KEY_MAP.DATE_ID)}
                id="date-id-input"
                label="Post ID"
              />
            )}
            {data.type === NOTIFICATION_TYPES_MAP.USER_PROFILE && (
              <AppOutlinedInput
                value={data.userId}
                setValue={handleChangeData(NOTIFICATION_DATA_KEY_MAP.USER_ID)}
                id="user-id-input"
                label="User ID"
              />
            )}
            {data.type === NOTIFICATION_TYPES_MAP.CUSTOM_URL && (
              <AppOutlinedInput
                value={data.url}
                setValue={handleChangeData(NOTIFICATION_DATA_KEY_MAP.URL)}
                id="url-input"
                label="URL"
              />
            )}
          </Box>
        }
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
    </Fragment>
  );
};

const DEFAULT_DATA = {
  [NOTIFICATION_DATA_KEY_MAP.URL]: '',
  [NOTIFICATION_DATA_KEY_MAP.TYPE]: NOTIFICATION_TYPES_MAP.CUSTOM_URL,
  [NOTIFICATION_DATA_KEY_MAP.DATE_ID]: '',
  [NOTIFICATION_DATA_KEY_MAP.USER_ID]: ''
};

const useData = (selectedIds) => {
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(DEFAULT_DATA);

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('users');
  const onSuccess = () => {
    refresh();
    notify('Notification sent');
    unselectAll();
  };
  const onError = () => notify('Error: notification not sent', { type: 'error' });
  const [{ loading }, send] = useHttp(CUSTOM_ENDPOINTS.sendTestNotification(), REQUEST_TYPES.POST, {
    onSuccess: onSuccess,
    onError: onError
  });

  const handleChangeData = (prop) => (e) => setData({ ...data, [prop]: e.target.value });
  const handleConfirm = () => {
    const body = {
      data: data,
      usersUUIDs: selectedIds,
      title: title,
      body: text
    };

    send({ body });
    setOpen(false);
    setData(DEFAULT_DATA);
    setText('');
    setTitle('');
  };

  return {
    text,
    setText,
    title,
    setTitle,
    open,
    setOpen,
    data,
    loading,
    handleChangeData,
    handleConfirm
  };
};

const AppOutlinedInput = ({ value, setValue, label, id = 'input' }) => (
  <FormControl variant="outlined" size="small" margin="normal">
    <InputLabel id={`${id}-label`}>{label}</InputLabel>
    <OutlinedInput
      id={id}
      value={value}
      onChange={setValue}
      label={label}
      labelId={`${id}-label`}
    />
  </FormControl>
);

const TypeSelector = ({ value, setValue }) => {
  return (
    <SingleSelect
      value={value}
      label="Notification type"
      onChange={setValue}
      size="small"
      choices={NOTIFICATION_TYPES}
      valueKey="id"
      labelKey="name"
      formControlProps={{ margin: 'normal' }}
    />
  );
};

export default SendNotificationButton;
