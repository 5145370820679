import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

import SnapchatIcon from './svgs/snapchat.svg';
import TiktokIcon from './svgs/tiktok.svg';
import { SOCIAL_MEDIA_LINK_KEYS } from './constants';

export const getSocialIconFromKey = (key) => {
  switch (key) {
    case SOCIAL_MEDIA_LINK_KEYS.FACEBOOK:
      return <FacebookIcon />;
    case SOCIAL_MEDIA_LINK_KEYS.LINKED_IN:
      return <LinkedInIcon />;
    case SOCIAL_MEDIA_LINK_KEYS.TWITTER:
      return <TwitterIcon />;
    case SOCIAL_MEDIA_LINK_KEYS.INSTAGRAM:
      return <InstagramIcon />;
    case SOCIAL_MEDIA_LINK_KEYS.TIKTOK:
      return <img src={TiktokIcon} alt="Tiktok Logo" style={{ width: 22 }} />;
    case SOCIAL_MEDIA_LINK_KEYS.SNAPCHAT:
      return <img src={SnapchatIcon} alt="Snapchat Logo" style={{ width: 22 }} />;
  }
};
