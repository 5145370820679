import React from 'react';
import { useRecordContext } from 'react-admin';

import SupportMessagesList from '~/components/SupportMessagesList';

const SupportTabContent = (props) => {
  const { id } = useRecordContext(props);

  return <SupportMessagesList filter={{ userId: id }} />;
};

export default SupportTabContent;
