import React from 'react';
import { useRecordContext } from 'react-admin';

import DatesList from '~/components/DatesList';

const PostedDatesTabContent = (props) => {
  const { id } = useRecordContext(props);

  return <DatesList title=" Posted posts" filter={{ userId: id }} emptyText="No posted posts" />;
};

export default PostedDatesTabContent;
