import { mapToSelector } from '~/utils/helpers';

export const EVENT_VISIBILITY_MAP = {
  PUBLIC: 'public',
  PRIVATE: 'private'
};

export const EVENT_PARTICIPANT_STATES_MAP = {
  PENDING: 'pending',
  APPROVED: 'approved',
  WAITLISTED: 'waitlisted',
  WAITLISTED_REJECTED: 'rejected'
};

export const EVENT_PARTICIPANT_VIP_STATUSES_MAP = {
  GA: 'ga',
  STAFF: 'staff',
  VIP: 'vip',
  TALENT: 'talent'
};

export const EVENT_VISIBILITY = mapToSelector(EVENT_VISIBILITY_MAP);
export const EVENT_PARTICIPANT_STATES = mapToSelector(EVENT_PARTICIPANT_STATES_MAP);
export const EVENT_PARTICIPANT_VIP_STATUSES = mapToSelector(EVENT_PARTICIPANT_VIP_STATUSES_MAP);
