import React from 'react';

import SupportMessagesList from '~/components/SupportMessagesList';
import UserLinkField from '~/components/UserLinkField';

export default (props) => {
  return (
    <SupportMessagesList {...props}>
      <UserLinkField label="User" />
    </SupportMessagesList>
  );
};
