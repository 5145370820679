import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import { withAccessChecking } from '~/utils/helpers';

import List from './core/List';
import Edit from './core/Edit';
import Create from './core/Create';

export default {
  name: 'referrals/monthy-prizes',
  list: withAccessChecking(List),
  edit: withAccessChecking(Edit),
  create: withAccessChecking(Create),
  icon: EmojiEventsIcon,
  options: {
    label: 'Prizes'
  }
};
