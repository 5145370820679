import React from 'react';
import { FunctionField } from 'react-admin';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import { addHttpToLink } from '~/utils/helpers';

const AdditionalLinksField = (props) => {
  const renderContent = (record) => {
    if (record.additionalLinks === null || record.additionalLinks.length === 0) {
      return '-';
    }

    return (
      <Stack direction="column">
        {record.additionalLinks.map((link, index) => {
          const href = addHttpToLink(link);

          return (
            <Link key={index} href={href} target="_blank" rel="noreferrer">
              {link}
            </Link>
          );
        })}
      </Stack>
    );
  };

  return <FunctionField {...props} render={renderContent} />;
};

export default AdditionalLinksField;
