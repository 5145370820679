import ReportIcon from '@mui/icons-material/Report';

import { withAccessChecking } from '~/utils/helpers';

import List from './core/List';
import Show from './core/Show';

export default {
  name: 'reports',
  list: withAccessChecking(List),
  show: withAccessChecking(Show),
  icon: ReportIcon,
  options: {
    label: 'Reports'
  }
};
