import React from 'react';
import { List, Datagrid, TextField, TextInput, SelectInput, EditButton } from 'react-admin';
import { useTheme } from '@mui/material/styles';

import UserLinkField from '~/components/UserLinkField';
import FullScreenImage from '~/components/FullScreenImage';
import { USER_VERIFY_STATUSES, USER_VERIFY_STATUSES_MAP } from '~/resources/constants';

const filters = [
  <TextInput key="userId" source="userId" label="User ID" resettable />,
  <SelectInput key="status" source="status" label="Status" choices={USER_VERIFY_STATUSES} />
];

const UserVerificationList = (props) => {
  const { palette } = useTheme();

  const getBackgroundColor = (status) => {
    switch (status) {
      case USER_VERIFY_STATUSES_MAP.APPROVED:
        return palette.success.main;

      case USER_VERIFY_STATUSES_MAP.REJECTED:
        return palette.status.danger;

      case USER_VERIFY_STATUSES_MAP.PENDING:
        return palette.warning.light;
    }
  };

  const rowStyle = (record) => ({
    backgroundColor: getBackgroundColor(record?.status)
  });

  return (
    <List {...props} title="User verifications" exporter={false} filters={filters} perPage={10}>
      <Datagrid rowClick="show" rowStyle={rowStyle} bulkActionButtons={false}>
        <TextField source="id" label="ID" />
        <TextField source="comment" label="Comment" emptyText="-" />
        <TextField source="status" label="Status" />
        <UserLinkField label="User" />
        <FullScreenImage src="firstPosePath" preview="firstPosePath" title="first pose image" />
        <FullScreenImage src="firstPhotoPath" preview="firstPhotoPath" title="first photo image" />
        <FullScreenImage src="secondPosePath" preview="secondPosePath" title="second pose image" />
        <FullScreenImage
          src="secondPhotoPath"
          preview="secondPhotoPath"
          title="second photo image"
        />
        <EditButton label="Edit" />
      </Datagrid>
    </List>
  );
};

export default UserVerificationList;
