import simpleRestProvider from 'ra-data-simple-rest';

import { REQUEST_TYPES } from '~/utils/constants';

import { httpClient } from './httpClient';

const apiUrl = '';
const dataProvider = simpleRestProvider(apiUrl, httpClient);

export default {
  ...dataProvider,
  getList: (resource, params) => dataProvider.getList(resource, params),
  create: (resource, params) => {
    const { data } = params;
    const dataIsFormData = data instanceof FormData;

    if (dataIsFormData) {
      const path = `${apiUrl}/${resource}`;

      return httpClient(path, {
        method: REQUEST_TYPES.POST,
        body: data
      }).then(({ json }) => ({
        data: { ...json, id: json.id }
      }));
    }

    return dataProvider.create(resource, params);
  },
  update: (resource, params) => {
    const { data } = params;
    const dataIsFormData = data instanceof FormData;

    if (dataIsFormData) {
      const path = `${apiUrl}/${resource}/${params.id}`;

      return httpClient(path, {
        method: REQUEST_TYPES.PUT,
        body: data
      }).then(({ json }) => ({ data: json }));
    }

    return dataProvider.update(resource, params);
  }
};
