import React from 'react';
import { Show as RAShow, TabbedShowLayout, Tab } from 'react-admin';

import AmbassadorsList from '../components/AmbassadorsList';
import SummaryTabContent from '../components/SummaryTabContent';
import EventParticipantsList from '../components/EventParticipantsList';

const Show = (props) => {
  return (
    <RAShow {...props}>
      <TabbedShowLayout>
        <Tab label="summary" path="">
          <SummaryTabContent />
        </Tab>

        <Tab label="ambassadors" path="ambassadors">
          <AmbassadorsList />
        </Tab>

        <Tab label="event participants" path="event-participants">
          <EventParticipantsList />
        </Tab>
      </TabbedShowLayout>
    </RAShow>
  );
};

export default Show;
