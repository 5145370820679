import React from 'react';
import { useListContext, SelectInput } from 'react-admin';

import { USER_ROLES_MAP, SIGN_UP_STEPS } from '../constants';

const SignUpStepFilter = (props) => {
  const { filterValues } = useListContext();
  const isWaitlist = filterValues.role === USER_ROLES_MAP.WAITLIST_USER;

  if (isWaitlist) {
    return (
      <SelectInput source="signUpStep" label="Sign up step" choices={SIGN_UP_STEPS} {...props} />
    );
  }

  return null;
};

export default SignUpStepFilter;
