import React from 'react';
import { FunctionField } from 'react-admin';
import Chip from '@mui/material/Chip';

const TagsField = () => {
  const render = (record) =>
    record.tags.map((value) => {
      return <Chip key={value} label={value} style={{ margin: '4px 4px 4px 4px' }} />;
    });

  return <FunctionField render={render} />;
};

export default TagsField;
