import React from 'react';
import { Show, TabbedShowLayout, Tab } from 'react-admin';

import useStyles from '../../styles';
import {
  SummaryTabContent,
  SupportTabContent,
  SignupQuestionsTabContent,
  GalleryTabContent,
  ReportsTabContent,
  PostedDatesTabContent,
  ProfileSectionsTabContent,
  InvitedUsersTabContent,
  AppliedDatesTabContent
} from '../tabs';

export default (props) => {
  const classes = useStyles();

  return (
    <Show {...props} className={classes.raFieldWorkBreak}>
      <TabbedShowLayout>
        <Tab label="summary">
          <SummaryTabContent />
        </Tab>

        <Tab label="gallery">
          <GalleryTabContent />
        </Tab>

        <Tab label="signup questions">
          <SignupQuestionsTabContent />
        </Tab>

        <Tab label="profile sections">
          <ProfileSectionsTabContent />
        </Tab>

        <Tab label="reports" path="reports">
          <ReportsTabContent />
        </Tab>

        <Tab label="support messages" path="support-messages">
          <SupportTabContent />
        </Tab>

        <Tab label="posted posts" path="posted-posts">
          <PostedDatesTabContent />
        </Tab>

        <Tab label="applied events" path="applied-events">
          <AppliedDatesTabContent />
        </Tab>

        <Tab label="invited users" path="invited-users">
          <InvitedUsersTabContent />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
