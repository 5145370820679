import React from 'react';
import { SimpleShowLayout, ArrayField, Datagrid, TextField, FunctionField } from 'react-admin';
import Typography from '@mui/material/Typography';

import FullScreenImage from '~/components/FullScreenImage';

import { PROFILE_SECTIONS_CONTENT_TYPES } from '../constants';

const ContentField = (props) => {
  return (
    <FunctionField
      {...props}
      render={(record) => {
        switch (record.type) {
          case PROFILE_SECTIONS_CONTENT_TYPES.TEXT:
            return <TextField source="text" />;

          case PROFILE_SECTIONS_CONTENT_TYPES.PHOTO:
            return (
              <FullScreenImage src="filePath" preview="previewPath" title="profile content image" />
            );

          case PROFILE_SECTIONS_CONTENT_TYPES.VIDEO:
            return (
              <video width="240" height="160" controls>
                <source src={record.filePath} />
                Your browser does not support the video tag.
              </video>
            );

          case PROFILE_SECTIONS_CONTENT_TYPES.AUDIO:
            return (
              <audio controls>
                <source src={record.filePath} />
                Your browser does not support the audio element.
              </audio>
            );

          default:
            return <TextField source="text" emptyText="-" />;
        }
      }}
    />
  );
};

const ProfileSectionsTabContent = () => {
  return (
    <SimpleShowLayout>
      <ArrayField source="profileSections" label="">
        <Datagrid
          bulkActionButtons={false}
          empty={<Typography variant="h6">No profile sections</Typography>}
        >
          <TextField source="question" label="Question" sortable={false} />
          <ContentField label="Content" sortable={false} />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  );
};

export default ProfileSectionsTabContent;
