import React from 'react';
import { useRecordContext, NumberField } from 'react-admin';

import UserLinkField from '~/components/UserLinkField';
import InvitedUsersList from '~/components/InvitedUsersList';

import AmbassadorInvitedUsersTotal from '../components/AmbassadorInvitedUsersTotal';
import { USER_ROLES_MAP } from '../constants';

const InvitedUsersTabContent = (props) => {
  const { id, role } = useRecordContext(props);
  const isAmbassador = role === USER_ROLES_MAP.AMBASSADOR;

  return (
    <div>
      {isAmbassador && <AmbassadorInvitedUsersTotal userId={id} />}
      <InvitedUsersList filter={{ invitingUserId: id }}>
        {isAmbassador && [
          <UserLinkField
            key="invitedBy"
            idKey="invitingUserId"
            nameKey="invitingUserName"
            label="Invited By"
            sortable={false}
          />,
          <NumberField key="tier" label="Tier" source="tier" emptyText="-" sortable={false} />,
          <NumberField
            key="payout"
            label="Payout($)"
            source="payout"
            emptyText="-"
            sortable={false}
          />,
          <NumberField
            key="revShare"
            label="RevShare($)"
            source="revShare"
            emptyText="-"
            sortable={false}
          />,
          <NumberField key="total" label="Total($)" source="total" emptyText="-" sortable={false} />
        ]}
      </InvitedUsersList>
    </div>
  );
};

export default InvitedUsersTabContent;
