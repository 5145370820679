import { makeStyles } from '@mui/styles';

export default makeStyles({
  previewImage: {
    maxWidth: '240px',
    maxHeight: '160px'
  },
  fullImage: {
    maxWidth: '720px',
    paddingTop: '48px',
    maxHeight: '480px',
    marginTop: 0,
    marginBottom: 0
  },
  fullImageWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 3
  },
  verticalPreviewImage: {
    maxWidth: '90px',
    height: 'auto',
    objectFit: 'cover',
    aspectRatio: '9/19.5'
  },
  verticalFullImage: {
    maxWidth: '720px',
    objectFit: 'cover',
    aspectRatio: '9/19.5',
    paddingTop: '48px',
    marginTop: 0,
    marginBottom: 0
  }
});
