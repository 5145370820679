import React from 'react';
import {
  TextField,
  DateField,
  Datagrid,
  Pagination,
  List,
  TextInput,
  SelectInput,
  BooleanField
} from 'react-admin';
import { useTheme } from '@mui/material/styles';

import UserLinkField from '../UserLinkField';
import ReviewedButton from '../ReviewedButton';
import useStyles from '../styles';
import { REPORT_TYPES, REPORT_REASONS } from './constants';

export const defaultFilters = [
  <TextInput key="search" source="search" label="Search" alwaysOn resettable />,
  <TextInput key="userId" source="userId" label="User ID" resettable />,
  <TextInput key="datingId" source="datingId" label="Dating ID" resettable />,
  <SelectInput key="reportType" source="reportType" label="Report type" choices={REPORT_TYPES} />,
  <SelectInput key="reason" source="reason" label="Reason" choices={REPORT_REASONS} />
];

const ReportsList = ({ children, dataGridOptions = {}, ...other }) => {
  const classes = useStyles();
  const { palette } = useTheme();

  const rowStyle = (record) => ({
    backgroundColor: record?.reviewed ? palette.success.main : ''
  });

  return (
    <List
      className={classes.table}
      resource="reports"
      title=" Reports"
      perPage={10}
      exporter={false}
      pagination={<Pagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={defaultFilters}
      {...other}
    >
      <Datagrid rowStyle={rowStyle} rowClick="show" bulkActionButtons={false} {...dataGridOptions}>
        <TextField source="id" label="ID" />
        <DateField source="createdAt" label="Created at" locales="en-EN" showTime />
        <DateField source="updatedAt" label="Updated at" locales="en-EN" showTime />
        <TextField source="text" label="Text" emptyText="-" />
        <TextField source="reason" label="Reason" />
        <TextField source="type" label="Type" />
        <TextField source="datingId" label="Dating ID" emptyText="-" />
        <BooleanField source="reviewed" label="Reviewed" />
        <UserLinkField label="User" />
        {children}
        <ReviewedButton label="Review" />
      </Datagrid>
    </List>
  );
};

export default ReportsList;
