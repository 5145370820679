import React from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

export default ({
  control,
  name,
  inputLabelProps = {},
  helperText,
  step,
  min,
  max,
  ...otherProps
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const marginBottom = helperText ? '16px' : '36px';

        return (
          <TextField
            {...field}
            size="small"
            fullWidth
            type="number"
            style={{ margin: `0 0 ${marginBottom} 0`, minWidth: '233px' }}
            helperText={helperText}
            inputProps={{ max, min, step }}
            {...otherProps}
            InputLabelProps={{
              shrink: true,
              ...inputLabelProps
            }}
          />
        );
      }}
    />
  );
};
