import format from 'date-fns/format';
import { enCA } from 'date-fns/locale';

export default (date, formatString, options = {}) => {
  if (typeof date === 'string') {
    try {
      date = new Date(date);
    } catch (err) {
      console.error(err);
    }
  }

  return format(date, formatString, {
    locale: enCA,
    weekStartsOn: 1,
    ...options
  });
};
